function parseSearch(url) {
  const removeQuestionMark = url.trim().replace(/^[?#&]/, "");
  const retrieveQueryKey = removeQuestionMark.split("&");
  const object = Object.assign(
    {},
    ...retrieveQueryKey.map(e => {
      const [key, value] = e.split("=");
      return { [key]: value };
    })
  );
  return object;
}
export default parseSearch;
