import React, { useState, useMemo } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import moment from "moment";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DatePicker from "react-datepicker";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { SketchPicker } from "react-color";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Cached from "@material-ui/icons/Cached";
import Close from "@material-ui/icons/Close";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import updateAdvisor from "api/advisor/updateAdvisor.js";
import SweetAlert from "react-bootstrap-sweetalert";
import getUsersList from "api/user/getUsersList.js";
import resetPassword from "api/user/resetPassword.js";
import updateUser from "api/user/updateUser.js";
import deleteUser from "api/user/deleteUser.js";

const styles = {
  ...style,
  right: {
    float: "right"
  },
  left: {
    float: "left"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

function randomPassword(length) {
  var chars = "abcdefghijklmnopqrstuvwxyz!&<?>ABCDEFGHIJKLMNOP1234567890";
  var pass = "";
  for (var x = 0; x < length; x++) {
    var i = Math.floor(Math.random() * chars.length);
    pass += chars.charAt(i);
  }
  return pass;
}
const useStyles = makeStyles(styles);
function datePicker(start, end, filter, onChange) {
  return (
    <>
      <DatePicker
        style={{ float: "left", width: "50%" }}
        selected={start}
        onChange={date => {
          const fil = filter && filter.value ? filter.value : {};
          fil.start = date;
          onChange(fil);
        }}
        selectsStart
        dateFormat="MM/yyyy"
        showMonthYearPicker
        startDate={start}
        endDate={end}
      />
      <DatePicker
        selected={end}
        onChange={date => {
          const fil = filter && filter.value ? filter.value : {};
          fil.end = date;
          onChange(fil);
        }}
        selectsEnd
        dateFormat="MM/yyyy"
        showMonthYearPicker
        endDate={end}
        startDate={start}
        minDate={start}
      />
    </>
  );
}
function customSelect(value, menuType, classes, change) {
  return (
    <Select
      value={value}
      onChange={e => {
        change(e.target.value);
      }}
      MenuProps={{ className: classes.selectMenu }}
      classes={{ select: classes.select }}
      style={{ width: "100%" }}
    >
      {menuType.map(el => {
        return (
          <MenuItem
            key={el}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelectedMultiple
            }}
            value={el}
          >
            {el}
          </MenuItem>
        );
      })}
    </Select>
  );
}

export function EditableCheck(props) {
  const { classes, variables } = props;
  const [value, setValue] = useState(variables === "true");
  return (
    <Checkbox
      style={{ width: "20px", height: "19px" }}
      checked={value}
      onClick={() => {
        setValue(!value);
        if (props.update) {
          props.update(!value);
        }
      }}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<></>}
    />
  );
}

export default function ReactTables(props) {
  const [users, setUsers] = useState([]);
  const rederData = useMemo(
    () =>
      users.map(prop => {
        let totalActivity = 0;
        if (prop.statistics) {
          if (prop.statistics.checkIn && prop.statistics.checkIn.total) {
            totalActivity += prop.statistics.checkIn.total;
          }
          if (prop.statistics.reviews && prop.statistics.reviews.total) {
            totalActivity += prop.statistics.reviews.total;
          }
          if (prop.statistics.photo) {
            totalActivity += prop.statistics.photo;
          }
          if (prop.statistics.chiusure) {
            totalActivity += prop.statistics.chiusure;
          }
          if (prop.statistics.editCategories) {
            totalActivity += prop.statistics.editCategories;
          }
          if (prop.statistics.editFoodType) {
            totalActivity += prop.statistics.editFoodType;
          }
          if (prop.statistics.editIdealfor) {
            totalActivity += prop.statistics.editIdealfor;
          }
          if (prop.statistics.editLocation) {
            totalActivity += prop.statistics.editLocation;
          }
        }
        return {
          id: prop._id,
          username: prop.userDataId.username,
          email: prop.userDataId.email,
          attività: totalActivity,
          registrato: moment(prop.userDataId.registeredSince).format(
            "DD/MM/YYYY"
          ),
          checkIn: prop.statistics.checkIn.total,
          recensioni: prop.statistics.reviews.total,
          verificato: (!prop.verificationToken).toString(),
          advisor: (!!(prop.userDataId.permissions === "ADVISOR")).toString(),
          ambassador: (!!prop.userDataId.ambassador).toString(),
          color: prop.userDataId.color,
          userDataId: prop.userDataId._id
        };
      }),
    [users]
  );

  const [sort, setSort] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Lista utanti</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              onSortedChange={newSorted => {
                setSort(newSorted);
              }}
              onFetchData={state => {
                if (JSON.stringify(sort) !== JSON.stringify(state.sorted)) {
                  return null;
                }
                const arrayToObject = array =>
                  array.reduce((obj, item) => {
                    obj[item.id] = item.value;
                    return obj;
                  }, {});

                const filter = arrayToObject(state.filtered);
                getUsersList(filter).then(a => {
                  setUsers(a.users);
                  return a.users;
                });
              }}
              columns={[
                {
                  Header: "_id",
                  accessor: "id"
                },
                {
                  Header: "username",
                  accessor: "username",
                  filterMethod: () => true
                },
                {
                  Header: "email",
                  accessor: "email",
                  filterMethod: () => true
                },
                {
                  Header: "reg",
                  accessor: "registrato",
                  Filter: ({ filter, onChange }) => {
                    const start =
                      filter && filter.value && filter.value.start
                        ? filter.value.start
                        : new Date("2016-01-01");
                    const end =
                      filter && filter.value && filter.value.end
                        ? filter.value.end
                        : new Date();
                    return datePicker(start, end, filter, onChange);
                  },
                  filterMethod: () => true
                },
                {
                  Header: "attività",
                  accessor: "attività",
                  filterable: false
                },
                {
                  Header: "check",
                  accessor: "checkIn",
                  filterable: false
                },
                {
                  Header: "rev",
                  accessor: "recensioni",
                  filterable: false
                },
                {
                  Header: "ver",
                  accessor: "verificato",
                  Filter: ({ filter, onChange }) => {
                    return customSelect(
                      filter ? filter.value : "ALL",
                      ["", "true", "false"],
                      classes,
                      onChange
                    );
                  },
                  filterMethod: () => true,
                  Cell: function Ver(row) {
                    return (
                      <div
                        style={{
                          // float: "left",
                          width: "24px",
                          margin: "0 auto",
                          border: "1px solid"
                        }}
                      >
                        <EditableCheck
                          key={`${row._id} verificato`}
                          variables={row.row.verificato}
                          classes={classes}
                          update={value => {
                            return updateUser({
                              _id: row.original._id,
                              userKeys: { verificationToken: null }
                            }).then(data => {
                              if (data) {
                                const user = [...users];
                                user[row.index].verificationToken = value
                                  ? null
                                  : true;
                                return setUsers(user);
                              }
                            });
                          }}
                        />
                      </div>
                    );
                  }
                },
                {
                  Header: "amb",
                  accessor: "ambassador",
                  Filter: ({ filter, onChange }) => {
                    return customSelect(
                      filter ? filter.value : "ALL",
                      ["", "true", "false"],
                      classes,
                      onChange
                    );
                  },
                  filterMethod: () => true,
                  Cell: function Amb(row) {
                    return (
                      <div
                        style={{
                          width: "24px",
                          margin: "0 auto",
                          border: "1px solid"
                        }}
                      >
                        <EditableCheck
                          key={`${row._id} Amb`}
                          variables={row.row.ambassador}
                          classes={classes}
                          update={value => {
                            return updateUser({
                              userDataId: row.original.userDataId,
                              userData: { ambassador: value }
                            }).then(data => {
                              if (data) {
                                const user = [...users];
                                user[row.index].userDataId.ambassador = value;
                                return setUsers(user);
                              }
                            });
                          }}
                        />
                      </div>
                    );
                  }
                },
                {
                  Header: "adv",
                  headerStyle: { textAlign: "left" },
                  accessor: "advisor",
                  style: {
                    textAlign: "left"
                  },
                  Cell: function Adv(row) {
                    const [display, setDisplay] = useState(false);
                    const [color, setColor] = useState(row.original.color);
                    return (
                      <div>
                        <div
                          style={{
                            float: "left",
                            border: "1px solid"
                          }}
                        >
                          <EditableCheck
                            key={`${row.row._id} Adv`}
                            variables={row.row.advisor}
                            classes={classes}
                            update={value => {
                              if (value === true) {
                                updateAdvisor(row.original.userDataId, {
                                  permissions: "ADVISOR",
                                  color
                                }).then(data => {
                                  if (data) {
                                    const user = [...users];
                                    user[row.index].userDataId.permissions =
                                      "ADVISOR";
                                    user[row.index].userDataId.color = color;
                                    setUsers(user);
                                  }
                                });
                              } else {
                                updateAdvisor(row.original.userDataId, {
                                  permissions: "FOODIE",
                                  color: {}
                                }).then(data => {
                                  if (data) {
                                    const user = [...users];
                                    user[row.index].userDataId.permissions =
                                      "FOODIE";
                                    user[row.index].userDataId.color = {};
                                    setUsers(user);
                                  }
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="actions-center">
                          <div
                            style={{
                              width: "100%"
                            }}
                            onClick={() => {
                              setDisplay(true);
                            }}
                          >
                            <div
                              className="actions-center"
                              style={{
                                margin: "0 auto",
                                width: "20px",
                                border: "1px solid",
                                height: "20px",
                                borderRadius: "50%",
                                background: `${
                                  color
                                    ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
                                    : "rgba(0,0,0,0)"
                                }`
                              }}
                            />
                          </div>
                          {display ? (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: "2"
                              }}
                            >
                              <div
                                style={{
                                  position: "fixed",
                                  top: "0px",
                                  right: "0px",
                                  bottom: "0px",
                                  left: "0px"
                                }}
                                onClick={() => {
                                  setDisplay(false);
                                  console.log(row.original.userDataId, color);
                                  updateAdvisor(row.original.userDataId, {
                                    permissions: "ADVISOR",
                                    color
                                  }).then(data => {
                                    if (data) {
                                      const user = [...users];
                                      user[row.index].userDataId.permissions =
                                        "ADVISOR";
                                      user[row.index].userDataId.color = color;
                                      setUsers(user);
                                    }
                                  });
                                }}
                              />
                              <SketchPicker
                                color={color}
                                onChange={(colorSelected, event) => {
                                  setColor(colorSelected.rgb);
                                }}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    return customSelect(
                      filter ? filter.value : "ALL",
                      ["", "true", "false"],
                      classes,
                      onChange
                    );
                  }
                },
                {
                  Header: "Reset/Delete",
                  headerStyle: { textAlign: "left" },
                  sortable: false,
                  filterable: false,
                  style: {
                    textAlign: "left"
                  },
                  Cell: function Reset(row) {
                    return (
                      <>
                        <Button
                          round
                          justIcon
                          simple
                          color="success"
                          onClick={() => {
                            const password = randomPassword(8);
                            props.updateAlert({
                              title: "Sicuro di voler cambiare la password?",
                              text: (
                                <>
                                  <h4>
                                    <b>{"Email: "}</b>
                                    {`${row.original.email}`}
                                  </h4>
                                  <h4>
                                    <b>{"Password nuova :"}</b> {password}
                                  </h4>
                                </>
                              ),
                              confirmBtnText: "Cambia password",
                              showCancel: true,
                              confirm: () => {
                                return resetPassword({
                                  password: password,
                                  id: row.original.id,
                                  userDataId: row.original.userDataId,
                                  email: row.original.email,
                                  username: row.original.username
                                }).then(response => {
                                  console.log(response);
                                  if (response && response.message) {
                                    props.updateAlert({
                                      success: true,
                                      title: "Successo"
                                    });
                                  }
                                });
                              }
                            });
                          }}
                        >
                          <Cached />
                        </Button>
                        <Button
                          round
                          justIcon
                          simple
                          color="danger"
                          onClick={() => {
                            props.updateAlert({
                              title: "Sicuro di voler cancellare l'utente?",
                              text: (
                                <>
                                  <h4>
                                    <b>{"Username: "}</b>
                                    {`${row.original.username}`}
                                  </h4>
                                  <h4>
                                    <b>{"Email :"}</b> {row.original.email}
                                  </h4>
                                </>
                              ),
                              confirmBtnText: "Cancella utente",
                              showCancel: true,
                              confirm: () => {
                                return deleteUser(row.original.id).then(
                                  response => {
                                    if (response && response.message) {
                                      props.updateAlert({
                                        title: "Successo",
                                        success: true
                                      });
                                      const user = [...users];
                                      user.splice(row.index, 1);
                                      setUsers(user);
                                    }
                                  }
                                );
                              }
                            });
                            // setShowAlert({
                            //   title: "Sicuro di voler cancellare l'utente?",
                            //   text: (
                            //     <>
                            //       <h4>
                            //         <b>{"Username: "}</b>
                            //         {`${row.original.username}`}
                            //       </h4>
                            //       <h4>
                            //         <b>{"Email :"}</b> {row.original.email}
                            //       </h4>
                            //     </>
                            //   ),
                            //   confirmBtnText: "Cancella utente",
                            //   confirm: () => {
                            //     deleteUser(row.original.id).then(response => {
                            //       if (response && response.message) {
                            //         setShowAlert(null);
                            //         const user = [...users];
                            //         user.splice(row.index, 1);
                            //         setUsers(user);
                            //       }
                            //     });
                            //   }
                            // });
                          }}
                        >
                          <Close />
                        </Button>
                      </>
                    );
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
      {showAlert ? (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={showAlert.title}
          onConfirm={showAlert.confirm}
          // onConfirm={() => {
          //   resetPassword({
          //     password: showAlert.password,
          //     id: showAlert.id,
          //     userDataId: showAlert.userDataId,
          //     email: showAlert.email,
          //     username: showAlert.username
          //   }).then(response => {
          //     if (response && response.message) {
          //       setShowAlert(null);
          //     }
          //   });
          // }}
          onCancel={() => {
            setShowAlert(null);
          }}
          cancelBtnCssClass={
            classes.button + " " + classes.danger + " " + classes.right
          }
          confirmBtnCssClass={
            classes.button + " " + classes.success + " " + classes.left
          }
          confirmBtnText={showAlert.confirmBtnText}
          cancelBtnText="Chiudi"
          showCancel
          // confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {/* <>
            <h4>
              <b>{"Email: "}</b>
              {`${showAlert.email}`}
            </h4>
            <h4>
              <b>{"Password nuova :"}</b> {showAlert.password}
            </h4>
          </> */}
          {showAlert.text}
        </SweetAlert>
      ) : null}
    </GridContainer>
  );
}
