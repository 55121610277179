/*eslint no-useless-computed-key: "off"*/

import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import moment from "moment";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DatePicker from "react-datepicker";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

// API
import getOwnerList from "api/owner/getOwnerList.js";
import updateRestaurant from "api/restaurant/updateRestaurant.js";

const styles = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
function datePicker(start, end, filter, onChange) {
  return (
    <>
      <DatePicker
        style={{ float: "left", width: "50%" }}
        selected={start}
        onChange={date => {
          const fil = filter && filter.value ? filter.value : {};
          fil.start = date;
          onChange(fil);
        }}
        selectsStart
        dateFormat="MM/yyyy"
        showMonthYearPicker
        startDate={start}
        endDate={end}
      />
      <DatePicker
        selected={end}
        onChange={date => {
          const fil = filter && filter.value ? filter.value : {};
          fil.end = date;
          onChange(fil);
        }}
        selectsEnd
        dateFormat="MM/yyyy"
        showMonthYearPicker
        endDate={end}
        startDate={start}
        minDate={start}
      />
    </>
  );
}

export function EditableInput(prop) {
  const [value, setValue] = useState(prop.field);
  let formControlProps = { fullWidth: true };
  if (prop.formcontrolprops) {
    Object.assign(formControlProps, prop.formcontrolprops);
  }
  return (
    <CustomInput
      formControlProps={formControlProps}
      inputProps={{
        value: value,
        // onBlur: e => {
        //   console.log(e, value);
        // },
        onChange: e => {
          setValue(e.target.value);
        },
        ...prop
      }}
    />
  );
}

export function EditableCheck(props) {
  const { classes, variables } = props;
  const [value, setValue] = useState(variables === "true");
  return (
    <Checkbox
      checked={value}
      {...props}
      onClick={() => {
        setValue(!value);
        if (props.update) {
          props.update(!value);
        }
      }}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{
        checked: classes.checked,
        root: classes.checkRoot
      }}
    />
  );
}

export function EditableCheckProps(props) {
  const { classes } = props;
  return (
    <Checkbox
      {...props}
      checkedIcon={<Check className={classes.checkedIcon} />}
      icon={<Check className={classes.uncheckedIcon} />}
      classes={{
        checked: classes.checked,
        root: classes.checkRoot
      }}
    />
  );
}

export default function ReactTables() {
  const [data, setData] = useState([]);
  const [number, setNumber] = useState([]);
  const internalUpdateRestaurant = (_id, update, index, value, key) => {
    return updateRestaurant({
      _id,
      ...update
    }).then(res => {
      console.log(res);
      if (res && res.status) {
        const newDate = [...data];
        console.log(index);
        if (!newDate[index].ownerDetails) {
          newDate[index].ownerDetails = {};
        }
        newDate[index].ownerDetails[key] = value;
        console.log(newDate[index]);
        setData(newDate);
      }
    });
  };
  const rederData = useMemo(
    () =>
      [...data].map(prop => {
        let phone = "";
        let note = "";
        let interview = "";
        let date = "";
        let appointment = "";
        let sticker = false;
        let ownerName = "";
        let city = prop.addressIT.admin3 || prop.addressIT.city;
        if (prop.ownerDetails) {
          if (prop.ownerDetails.phone) {
            phone = prop.ownerDetails.phone;
          }
          if (prop.ownerDetails.note) {
            note = prop.ownerDetails.note;
          }
          if (prop.ownerDetails.interview) {
            interview = prop.ownerDetails.interview;
          }
          if (prop.ownerDetails.date) {
            date = moment(prop.ownerDetails.date).format("YYYY-MM-DD");
          }
          if (prop.ownerDetails.appointment) {
            appointment = moment(prop.ownerDetails.appointment).format(
              "YYYY-MM-DD"
            );
          }
          if (prop.ownerDetails.sticker) {
            sticker = prop.ownerDetails.sticker;
          }
          if (prop.ownerDetails.ownerName) {
            ownerName = prop.ownerDetails.ownerName;
          }
          if (prop.ownerDetails.city) {
            city = prop.ownerDetails.city;
          }
        } else if (
          prop.mainPhone &&
          prop.mainPhone.number &&
          prop.mainPhone.prefix
        ) {
          phone = prop.mainPhone.prefix + prop.mainPhone.number;
        }
        const owner = prop.roles.find(el => el.role === "OWNER");
        return {
          _id: prop._id,
          alias: prop.alias,
          name: prop.name,
          formattedAddress: prop.address.formattedAddress,
          phone: phone,
          owner: owner ? owner.username : "",
          haveNote: note.length > 0,
          note: note,
          interview: interview,
          haveInterview: interview.length > 0,
          date: date,
          service:
            prop.ownerDetails && prop.ownerDetails.service
              ? prop.ownerDetails.service
              : "Free",
          appointment: appointment,
          sticker: sticker,
          ownerName: ownerName,
          roles: prop.roles
            .filter(el => el.role !== "OWNER")
            .map(el => el.username + "/**/" + el.role)
            .join("/==/"),
          city: city,
          checkIn: prop.checkInNumber || 0,
          recensioni: prop.reviewNumber || 0,
          foto: prop.photoNumber || 0,
          certifiedBy:
            prop.certificationRequest &&
            prop.certificationRequest.status &&
            prop.certificationRequest.status === "fulfilled" &&
            prop.certificationRequest.userId
              ? prop.certificationRequest.userId.username
              : "",
          certificato: !!(
            prop.certificationRequest &&
            prop.certificationRequest.status &&
            prop.certificationRequest.status === "fulfilled"
          )
        };
      }),
    [data]
  );
  const subData = [];
  useEffect(() => {
    getOwnerList().then(a => {
      setData(a.restaurants);
      // setSubData(a.restaurants.map(el => ))
      setNumber(a.number);

      return null;
    });
  }, []);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Lista Owner {number}</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              columns={[
                {
                  Header: "date",
                  accessor: "date",
                  Cell: function Edit({ row, original, index }) {
                    return (
                      <EditableInput
                        key={`${original._id} date`}
                        field={row.date}
                        type={"date"}
                        onBlur={e => {
                          const value = e.target.value;
                          return internalUpdateRestaurant(
                            original._id,
                            { ["ownerDetails.date"]: value },
                            index,
                            value,
                            "date"
                          );
                        }}
                      />
                    );
                  }
                },
                {
                  Header: "Zona",
                  accessor: "city",
                  Cell: function Edit({ row, original, index }) {
                    return (
                      <EditableInput
                        key={`${original._id} city`}
                        field={row.city}
                        onBlur={e => {
                          const value = e.target.value;
                          return internalUpdateRestaurant(
                            original._id,
                            { ["ownerDetails.city"]: value },
                            index,
                            value,
                            "city"
                          );
                        }}
                      />
                    );
                  }
                },
                {
                  Header: "Nome",
                  accessor: "name",
                  Cell: function alias(row) {
                    return (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.foodiestrip.com/it/ristorante/${row.original.alias}`}
                      >
                        {row.row.name}
                      </a>
                    );
                  }
                },
                {
                  Header: "Advisor",
                  accessor: "certifiedBy"
                },
                {
                  Header: "telefono",
                  accessor: "phone",
                  Cell: function E({ row, original, index }) {
                    return (
                      <EditableInput
                        key={`${original._id} phone`}
                        field={row.phone}
                        onBlur={e => {
                          const value = e.target.value;
                          return internalUpdateRestaurant(
                            original._id,
                            { ["ownerDetails.phone"]: value },
                            index,
                            value,
                            "phone"
                          );
                        }}
                      />
                    );
                  }
                },

                {
                  Header: "certificato",
                  accessor: "certificato",
                  Cell: function Check({ row, original, index }) {
                    return (
                      <EditableCheck
                        key={`${original._id} cert`}
                        variables={row.certificato.toString()}
                        classes={classes}
                        update={value => {
                          if (value === true) {
                            return updateRestaurant({
                              _id: original._id,
                              ["certificationRequest.status"]: "fulfilled",
                              ["certificationRequest.date"]: new Date()
                            });
                          } else {
                            return updateRestaurant({
                              _id: original._id,
                              ["certificationRequest"]: {}
                            });
                          }
                        }}
                      />
                    );
                  }
                },
                {
                  Header: "Note",
                  accessor: "haveNote",
                  Cell: function Check({ row, original, index }) {
                    return (
                      <EditableCheckProps
                        key={`${original._id} note`}
                        checked={
                          subData[index] &&
                          (subData[index].note || subData[index].note === "")
                            ? subData[index].note.length > 0
                            : original.haveNote
                        }
                        classes={classes}
                        disabled
                      />
                    );
                  }
                },
                {
                  Header: "Intervista",
                  accessor: "haveInterview",
                  Cell: ({ row }) =>
                    EditableCheckProps({
                      checked:
                        subData[row._index] &&
                        (subData[row._index].interview ||
                          subData[row._index].interview === "")
                          ? subData[row._index].interview.length > 0
                          : row._original.haveInterview,
                      classes,
                      disabled: true
                    })
                },
                {
                  Header: "Servizi",
                  accessor: "service",
                  Cell: function S({ row, original, index }) {
                    // const [value, setValue] = useState(row.service);
                    // console.log(value);
                    return (
                      <Select
                        key={`${original._id} service`}
                        value={row.service}
                        onChange={e => {
                          const v = e.target.value;
                          const update = {
                            ["ownerDetails.service"]: e.target.value,
                            servicesPaid: []
                          };
                          if (v === "Free") {
                            update.servicesPaid = [];
                          } else if (
                            ["Essential", "Essential Plus", "Premium"].includes(
                              v
                            )
                          ) {
                            update.servicesPaid = [
                              { _id: 1 },
                              { _id: 2 },
                              { _id: 3 },
                              { _id: 4 },
                              { _id: 5 },
                              { _id: 6 },
                              { _id: 7 },
                              { _id: 8 },
                              { _id: 9 },
                              { _id: 10 }
                            ];
                          }
                          return internalUpdateRestaurant(
                            original._id,
                            update,
                            index,
                            v,
                            "service"
                          );
                        }}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        style={{ width: "100%" }}
                      >
                        {[
                          "Free",
                          "Essential",
                          "Essential Plus",
                          "Premium",
                          "Social",
                          "ADS"
                        ].map(el => (
                          <MenuItem _id={el} key={el} value={el}>
                            {el}
                          </MenuItem>
                        ))}
                      </Select>
                    );
                  }
                },
                {
                  Header: "Vetrofania",
                  accessor: "sticker",
                  headerStyle: { textAlign: "left" },
                  Cell: function Check({ row, original, index }) {
                    return (
                      <EditableCheck
                        key={`${original._id} sticker`}
                        variables={row.sticker.toString()}
                        classes={classes}
                        update={value => {
                          return internalUpdateRestaurant(
                            original._id,
                            {
                              ["ownerDetails.sticker"]: value
                            },
                            index,
                            value,
                            "sticker"
                          );
                        }}
                      />
                    );
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
              SubComponent={row => {
                return (
                  <>
                    <GridContainer
                      container
                      direction="row"
                      justify="space-evenly"
                      alignItems="baseline"
                      style={{ backgroundColor: "lightgray" }}
                    >
                      <GridItem xs={6} container>
                        <GridItem xs={6}>
                          <Card>
                            <CardHeader>
                              <h4 className={classes.title}>{"Owner:  "}</h4>
                            </CardHeader>
                            <CardBody>{row.original.owner}</CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={6}>
                          <Card>
                            <CardHeader>
                              <h4 className={classes.title}>{"address: "}</h4>
                            </CardHeader>
                            <CardBody>{row.original.formattedAddress}</CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={6}>
                          <Card>
                            <CardHeader>
                              <h4 className={classes.title}>
                                {"Altri Ruoli:  "}
                              </h4>
                            </CardHeader>
                            <CardBody>{row.original.roles}</CardBody>
                          </Card>
                        </GridItem>
                        <GridItem xs={6}>
                          <Card>
                            <CardHeader>
                              <h4 className={classes.title}>
                                {"Nome Owner:  "}
                              </h4>
                            </CardHeader>
                            <CardBody>
                              <EditableInput
                                key={`${subData[row.index]} name`}
                                field={
                                  subData[row.index] &&
                                  subData[row.index].ownerName
                                    ? subData[row.index].ownerName
                                    : row.original.ownerName
                                }
                                style={{ marginTop: "0px", paddingTop: "0px" }}
                                formcontrolprops={{
                                  style: { padding: 0 }
                                }}
                                onBlur={e => {
                                  if (!subData[row.index]) {
                                    subData[row.index] = {};
                                  }
                                  subData[row.index].ownerName = e.target.value;
                                  return updateRestaurant({
                                    _id: row.original._id,
                                    ["ownerDetails.ownerName"]: e.target.value
                                  });
                                }}
                              />
                            </CardBody>
                          </Card>
                        </GridItem>
                      </GridItem>
                      <GridItem container xs={6}>
                        <GridItem xs={6}>
                          <GridItem>
                            {" "}
                            <h4 className={classes.title}>{"Note:  "}</h4>
                          </GridItem>
                          <GridItem>
                            <EditableInput
                              field={
                                subData[row.index] &&
                                (subData[row.index].note ||
                                  subData[row.index].note === "")
                                  ? subData[row.index].note
                                  : row.original.note
                              }
                              multiline={true}
                              style={{
                                background: "white",
                                border: "1px solid"
                              }}
                              onBlur={e => {
                                if (!subData[row.index]) {
                                  subData[row.index] = {};
                                }
                                subData[row.index].note = e.target.value;
                                return updateRestaurant({
                                  _id: row.original._id,
                                  ["ownerDetails.note"]: e.target.value
                                });
                              }}
                              rows={10}
                            />
                          </GridItem>
                        </GridItem>
                        <GridItem xs={6}>
                          <GridItem>
                            {" "}
                            <h4 className={classes.title}>{"Intervista:  "}</h4>
                          </GridItem>

                          <GridItem>
                            <EditableInput
                              field={
                                subData[row.index] &&
                                (subData[row.index].interview ||
                                  subData[row.index].interview === "")
                                  ? subData[row.index].interview
                                  : row.original.interview
                              }
                              multiline={true}
                              style={{
                                background: "white",
                                border: "1px solid"
                              }}
                              rows={10}
                              onBlur={e => {
                                if (!subData[row.index]) {
                                  subData[row.index] = {};
                                }
                                console.log(e.target.value);
                                subData[row.index].interview = e.target.value;
                                return updateRestaurant({
                                  _id: row.original._id,
                                  ["ownerDetails.interview"]: e.target.value
                                });
                              }}
                            />
                          </GridItem>
                        </GridItem>
                      </GridItem>
                    </GridContainer>
                  </>
                );
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

EditableCheck.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  variables: PropTypes.bool
};
EditableCheck.defaultProps = {
  variables: false
};
