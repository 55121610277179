import React, { useState, useEffect, useMemo } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";

import L from "leaflet";
import HeatmapLayer from "react-leaflet-heatmap-layer";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Button from "components/CustomButtons/Button.js";

import CardHeader from "components/Card/CardHeader.js";
import resolveRequest from "api/changePosition/resolveRequest.js";
const redIcon = L.icon({
  iconRetinaUrl: require("assets/img/leafletIcon/marker-icon-2x-red.png"),
  iconUrl: require("assets/img/leafletIcon/marker-icon-red.png"),
  shadowUrl: require("assets/img/leafletIcon/marker-shadow.png"),
  iconSize: [22, 36],
  iconAnchor: [11, 36]
});
const blueIcon = L.icon({
  iconRetinaUrl: require("assets/img/leafletIcon/marker-icon-2x-blue.png"),
  iconUrl: require("assets/img/leafletIcon/marker-icon-blue.png"),
  shadowUrl: require("assets/img/leafletIcon/marker-shadow.png"),
  iconSize: [22, 36],
  iconAnchor: [11, 36]
});
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions(blueIcon);

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180);
}
export function distance([lon1, lat1], [lon2, lat2]) {
  const R = 6371e3; // metres
  const l1 = degreesToRadians(lat1);
  const l2 = degreesToRadians(lat2);
  const deltaLat = degreesToRadians(lat2 - lat1);
  const deltaLon = degreesToRadians(lon2 - lon1);

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(l1) *
      Math.cos(l2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
}

const renderHeatMapLayer = (array, gradient) => {
  const point = [].concat(
    ...array.map(el => el.userLocations.map(e => [e[1], e[0], 2]))
  );

  return (
    <>
      <HeatmapLayer
        _id={array._id}
        points={point}
        gradient={gradient}
        longitudeExtractor={m => {
          return m[1];
        }}
        latitudeExtractor={m => {
          return m[0];
        }}
        intensityExtractor={m => {
          return parseFloat(m[2]);
        }}
      />
    </>
  );
};
export default function RenderMap(data) {
  const [selected, setSelected] = useState(0);

  return (
    <Card>
      <CardHeader>
        {data.restaurant.restaurant && data.restaurant.restaurant.name
          ? data.restaurant.restaurant.name
          : ""}
      </CardHeader>
      <CardBody>
        <Map
          id={data.restaurant._id}
          center={[
            data.restaurant.restaurant.location.coordinates[1],
            data.restaurant.restaurant.location.coordinates[0]
          ]}
          maxZoom={18}
          minZoom={10}
          scrollWheelZoom={false}
          zoom={16}
          length={4}
          style={{ width: "100%", height: "500px", overflow: "hidden" }}
        >
          {data.restaurant.restaurantCheckIn
            ? renderHeatMapLayer(data.restaurant.restaurantCheckIn, {
                0.1: "#e7382f",
                0.2: "#f0c419",
                0.3: "#f04419",
                0.4: "#f0c459",
                0.6: "#f6c419",
                0.7: "#f0a419",
                0.8: "#f0d419",
                0.9: "#f0d519",

                1: "#ff0"
              })
            : null}
          {selected || selected === 0
            ? renderHeatMapLayer(
                data.restaurant.proposed[selected].backofficeCheckIn,
                null,
                true
                // { 0.4: "#e7382f", 0.65: "#f0c419", 1: "#fff" }
              )
            : null}
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data.restaurant.restaurant &&
          data.restaurant.restaurant.location &&
          data.restaurant.restaurant.location.coordinates ? (
            <Marker
              icon={blueIcon}
              anchor={0.5}
              position={{
                lng: data.restaurant.restaurant.location.coordinates[0],
                lat: data.restaurant.restaurant.location.coordinates[1]
              }}
            >
              {/* <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup> */}
            </Marker>
          ) : null}
          {selected || selected === 0 ? (
            <Marker
              key={data.restaurant.proposed[selected]._id}
              icon={redIcon}
              position={{
                lng:
                  data.restaurant.proposed[selected].proposedEdit.location
                    .coordinates[0],
                lat:
                  data.restaurant.proposed[selected].proposedEdit.location
                    .coordinates[1]
              }}
            >
              {/* <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup> */}
            </Marker>
          ) : null}
        </Map>
        {data && data.restaurant.proposed
          ? data.restaurant.proposed.map((el, i) => (
              <GridItem
                key={el._id}
                xs={12}
                onClick={() => {
                  setSelected(i);
                }}
              >
                <Card>
                  <CardHeader>
                    {el.userId ? (
                      <CardAvatar
                        profile
                        // className={classes.cardAvatar}
                        style={{
                          margin: 0,
                          maxWidth: "45px",
                          maxHeight: "45px",

                          position: "absolute",
                          // left: "-25px",
                          top: "0px"
                        }}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={el.userId.profileImageUrl}
                        />
                      </CardAvatar>
                    ) : null}
                  </CardHeader>
                  <CardBody>
                    <h4>
                      <b>{"Proposto da:"}</b>
                      {el.userId ? el.userId.username : ""}
                    </h4>
                    <h4>
                      <b>{"Via Suggerita:"}</b>
                      {el.proposedEdit &&
                      el.proposedEdit.address &&
                      el.proposedEdit.address.formattedAddress
                        ? el.proposedEdit.address.formattedAddress
                        : "indirizzo non disponibile"}
                    </h4>
                    <h4>
                      <b>{"Check-in fatti sul suggerimento:"}</b>
                      {" " + el.backofficeCheckIn.length}
                    </h4>
                    <h4>
                      <b>{"Distanza dal punto di partenza:"}</b>
                      {" " +
                        distance(
                          el.proposedEdit.location.coordinates,
                          data.restaurant.restaurant.location.coordinates
                        ).toFixed(2) +
                        " m"}
                    </h4>
                  </CardBody>
                  <CardFooter>
                    <Button
                      onClick={() => {
                        resolveRequest({
                          restaurantId: data.restaurant.restaurant._id,
                          backofficeId: el.backofficeId,
                          status: "fulfilled"
                        }).then(() => {
                          setSelected(0);
                          data.handleChange(data.dataIndex, i);
                        });
                      }}
                      color="success"
                      round
                    >
                      {"accetta suggerimento e checkIn"}
                    </Button>
                    <Button
                      onClick={() => {
                        resolveRequest({
                          restaurantId: data.restaurant.restaurant._id,
                          backofficeId: el.backofficeId,
                          status: "accepted"
                        }).then(() => {
                          setSelected(0);
                          data.handleChange(data.dataIndex, i);
                        });
                      }}
                      color="warning"
                      round
                    >
                      {"accetta solo checkIn"}
                    </Button>

                    <Button
                      onClick={() => {
                        resolveRequest({
                          restaurantId: data.restaurant.restaurant._id,
                          backofficeId: el.backofficeId,
                          status: "rejected"
                        }).then(() => {
                          setSelected(0);
                          data.handleChange(data.dataIndex, i);
                        });
                      }}
                      color="danger"
                      round
                    >
                      {"rifiuta suggerimento e checkIn"}
                    </Button>
                  </CardFooter>
                </Card>
              </GridItem>
            ))
          : null}
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}
