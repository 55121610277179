const getCoordinates = (address, key) => {
  // const endpoint = `${config.baseUrl}/api/tb/restaurants/update`;
  // const endpoint = "https://www.foodiestrip.com/api/tb/restaurants/update";
  const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}&language=it`;
  const options = {
    method: "GET"
  };
  return fetch(endpoint, options)
    .then(response => response.json())
    .then(response => {
      // eslint-disable-next-line no-console
      if (response.results[0]) {
        const location = response.results[0].geometry.location;
        // console.log('new location', location);
        return { lat: location.lat, lng: location.lng };
      }
      return null;
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log("errore", e);
    });
};

export default getCoordinates;
