import React, { useState, useEffect } from "react";
// grafica
import CardBasic from "componentCustom/CardBasic.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// funzionali
import CustomInput from "components/CustomInput/CustomInput.js";
import moment from "moment";
import Button from "components/CustomButtons/Button.js";
import ReactTable from "react-table";
import CsvDownload from "react-json-to-csv";
import Spinner from "componentCustom/Spinner.js";
//api
import createDelivery from "api/billing/createDelivery.js";
import createReservation from "api/billing/createReservation.js";

//Stile
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
//icone
import Assignment from "@material-ui/icons/Assignment";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function Generatore(props) {
  const max = moment()
    .subtract(1, "months")
    .format("YYYY-MM");
  return (
    <GridContainer>
      <GridItem xs={6}>
        <CustomInput
          id="month"
          style={{ width: "100%" }}
          inputProps={{
            defaultValue: props.month,
            type: "month",
            inputProps: {
              max: max
              // min: "2014-12"
            },
            onChange: e => {
              props.setMonth(e.target.value);
            }
          }}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button
          color="info"
          style={{ float: "right" }}
          onClick={() => {
            props.setShow(true);
            const value = props.month;
            console.log(props);
            const date = moment(value);
            const start = date.startOf("month").format("DD-MM-YYYY");

            const end = date.endOf("month").format("DD-MM-YYYY");
            console.log(start, end);

            return createDelivery(start, end)
              .then(delivery =>
                createReservation(start, end).then(reservation => ({
                  delivery,
                  reservation
                }))
              )
              .then(res => {
                props.setDelivery(res.delivery);
                props.setReservation(res.reservation);
                console.log(res);
                props.setShow(false);
              });
          }}
        >
          {" "}
          {"Genera"}
        </Button>
      </GridItem>
    </GridContainer>
  );
}

export default function Billing() {
  moment.locale("it");
  const classes = useStyles();
  const [delivery, setDelivery] = useState([]);
  const [reservation, setReservation] = useState([]);
  const [show, setShow] = useState(false);

  const [month, setMonth] = useState(
    moment()
      .subtract(1, "months")
      .format("YYYY-MM")
  );

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CardBasic
          icon={<Assignment />}
          title={"Generatore contatori"}
          body={
            <Generatore
              setDelivery={setDelivery}
              setShow={setShow}
              month={month}
              setMonth={setMonth}
              setReservation={setReservation}
            />
          }
        />
      </GridItem>

      <GridItem xs={12}>
        <CardBasic
          icon={<Assignment />}
          title={"Delivery"}
          footer={
            delivery && delivery.length > 0 ? (
              <CsvDownload
                filename={`delivery ${month}.csv`}
                data={delivery.map(el => ({
                  "nome del ristorante": el.name,
                  "ordini < 10€": el.lessThen10.toString().replace(".", ","),
                  "somma ordini < 10€": el.sumLessThen10,
                  "ordini > 10€": el.moreThen10,
                  oridini: el.totalOrder,
                  fatturato: el.fatturato.toString().replace(".", ",")
                }))}
              />
            ) : null
          }
          body={
            <ReactTable
              data={delivery}
              NoDataComponent={() => null}
              pageSize={delivery.length}
              footers={[]}
              showPaginationBottom={false}
              columns={[
                {
                  Header: "nome del ristorante",
                  accessor: "name"
                },
                {
                  Header: "ordini < 10€",
                  accessor: "lessThen10"
                },
                {
                  Header: "somma ordini < 10€",
                  accessor: "sumLessThen10"
                },
                {
                  Header: "ordini > 10€",
                  accessor: "moreThen10"
                },
                {
                  Header: "ordini",
                  accessor: "totalOrder"
                },
                {
                  Header: "fatturato",
                  headerStyle: {
                    textAlign: "left"
                  },
                  accessor: "fatturato"
                }
              ]}
            />
          }
        />
      </GridItem>
      <GridItem xs={12}>
        <CardBasic
          icon={<Assignment />}
          title={"Reservation"}
          footer={
            reservation && reservation.length > 0 ? (
              <CsvDownload
                filename={`reservation ${month}.csv`}
                data={reservation.map(el => ({
                  "nome del ristorante": el.name,
                  prenotazioni: el.prenotazioni,
                  "numero coperti": el.numeroCoperti
                }))}
              />
            ) : null
          }
          body={
            <ReactTable
              noDataText={""}
              data={reservation}
              pageSize={reservation.length}
              showPaginationBottom={false}
              columns={[
                {
                  Header: "nome del ristorante",
                  accessor: "name"
                },
                {
                  Header: "prenotazioni",
                  accessor: "prenotazioni"
                },
                {
                  Header: "Numero coperti",
                  headerStyle: {
                    textAlign: "left"
                  },
                  accessor: "numeroCoperti"
                }
              ]}
            />
          }
        />
      </GridItem>
      <Spinner show={show} />
    </GridContainer>
  );
}
