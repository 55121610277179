import React, { useState, useMemo, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Check from "@material-ui/icons/Check";

// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
import HowToReg from "@material-ui/icons/HowToReg";
import Restaurant from "@material-ui/icons/Restaurant";
import Close from "@material-ui/icons/Close";

import getOwnerPending from "api/owner/getOwnerPending.js";
import becomeOwner from "api/owner/becomeOwner.js";
import deleteRequest from "api/owner/deleteRequest.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const [restaurant, setRestaurant] = useState([]);
  const rederData = useMemo(() => {
    console.log(restaurant);
    return restaurant.map(prop => {
      console.log(prop, prop.checkIn);
      return {
        _id: prop._id,
        restaurantId: prop.restaurantId._id,
        alias: prop.restaurantId.alias,
        isNew: !prop.proposedOwner.restaurantId,
        ownerId: prop.userId._id,
        nomeOwner:
          prop.proposedOwner.firstName + " " + prop.proposedOwner.lastName,
        username: prop.userId ? prop.userId.username : "",
        nomeRistornate: prop.restaurantId.name,
        role: prop.proposedOwner.role,
        email: prop.proposedOwner.email,
        telefono: prop.proposedOwner.telephone
      };
    });
  }, [restaurant]);
  useEffect(() => {
    getOwnerPending().then(res => {
      setRestaurant(res);
      return null;
    });
  }, []);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <h4 className={classes.cardIconTitle}>{"Leggenda"}</h4>
          </CardHeader>
          <CardBody>
            <div className="actions-center">
              <HowToReg
                style={{
                  color: "#4caf50",
                  width: "25px",
                  height: "25px",
                  paddingTop: "7px",
                  marginBottom: "-4px"
                }}
              />
              <span>{"accetta tutto (Owner e struttura)"}</span>
              <Restaurant
                style={{
                  color: "#ff9800",
                  width: "25px",
                  height: "25px",
                  paddingTop: "7px",
                  marginBottom: "-4px"
                }}
              />
              <span>{"accetta solo la struttura"}</span>
              <Close
                style={{
                  color: "#f44336",
                  width: "25px",
                  height: "25px",
                  paddingTop: "7px",
                  marginBottom: "-4px"
                }}
              />
              <span>{"rifiuta tutto"}</span>
            </div>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{"Lista owner bloccati"}</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              columns={[
                {
                  Header: "restaurantId",
                  accessor: "restaurantId"
                },
                {
                  Header: "alias",
                  accessor: "alias",
                  Cell: function alias(row) {
                    return (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.foodiestrip.com/it/ristorante/${row.original.alias}`}
                      >
                        {row.row.alias}
                      </a>
                    );
                  }
                },
                {
                  Header: "nome ristornate",
                  accessor: "nomeRistornate"
                },
                {
                  Header: "username",
                  accessor: "username"
                },
                {
                  Header: "nomeOwner",
                  accessor: "nomeOwner"
                },
                {
                  Header: "role",
                  accessor: "role"
                },
                {
                  Header: "email",
                  accessor: "email"
                },
                {
                  Header: "telefono",
                  accessor: "telefono"
                  // headerStyle: {
                  //   textAlign: "left"
                  // }
                },
                {
                  Header: "funzioni",
                  headerStyle: {
                    textAlign: "left"
                  },
                  accessor: "",
                  sortable: false,
                  filterable: false,
                  // Filter: function Fil() {
                  //   return <div>{"accetta/solo ristorante/elimina"}</div>;
                  // },
                  Cell: function Fun(row) {
                    return (
                      <div className="actions-center">
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            return becomeOwner(row.original._id, "it").then(
                              () => {
                                const r = [].concat(restaurant);
                                r.splice(row.index, 1);
                                setRestaurant(r);
                              }
                            );
                          }}
                          color="success"
                          className="edit"
                        >
                          <HowToReg />
                        </Button>
                        {row.original.isNew ? (
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              return deleteRequest({
                                id: row.original._id,
                                restaurantId: row.original.restaurantId,
                                userId: row.original.ownerId
                              }).then(() => {
                                const r = [].concat(restaurant);
                                r.splice(row.index, 1);
                                setRestaurant(r);
                              });
                            }}
                            color={"warning"}
                            className="like"
                          >
                            <Restaurant />
                          </Button>
                        ) : null}
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            return deleteRequest({
                              id: row.original._id,
                              restaurantId: row.original.restaurantId,
                              userId: row.original.ownerId,
                              delete: true
                            }).then(() => {
                              const r = [].concat(restaurant);
                              r.splice(row.index, 1);
                              setRestaurant(r);
                            });
                          }}
                          color="rose"
                          className="remove"
                        >
                          <Close />
                        </Button>
                      </div>
                    );
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  if (rowInfo.original.isLocked || rowInfo.original.isPending) {
                    return {
                      style: {
                        background: "yellow"
                        // color: "black"
                      }
                    };
                  }
                }
                return {};
              }}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
