import React, { useState, useMemo } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { SketchPicker } from "react-color";
import Button from "components/CustomButtons/Button.js";

import Close from "@material-ui/icons/Close";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import getAdvisor from "api/advisor/getAdvisor.js";
import updateAdvisor from "api/advisor/updateAdvisor.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables(props) {
  const [users, setUsers] = useState([]);
  const rederData = useMemo(
    () =>
      users.map(prop => {
        console.log(prop);
        return {
          id: prop._id,
          username: prop.username,
          email: prop.email,
          color: prop.color,
          userDataId: prop.userDataId
          // attività: totalActivity,
          // registrato: prop.registeredSince
          // checkIn: prop.statistics.checkIn.total,
          // recensioni: prop.statistics.reviews.total,
          // verificato: (!prop.verificationToken).toString(),
          // advisor: (!!prop.userDataId.permissions === "ADVISOR").toString(),
          // ambassador: (!!prop.userDataId.ambassador).toString()
        };
      }),
    [users]
  );

  const [sort, setSort] = useState([]);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Lista advisor</h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              onSortedChange={newSorted => {
                setSort(newSorted);
              }}
              onFetchData={state => {
                // if (JSON.stringify(sort) !== JSON.stringify(state.sorted)) {
                //   return null;
                // }
                // const arrayToObject = array =>
                //   array.reduce((obj, item) => {
                //     obj[item.id] = item.value;
                //     return obj;
                //   }, {});
                // const filter = arrayToObject(state.filtered);
                getAdvisor().then(a => {
                  console.log(a);
                  setUsers(a.advisor);
                  return null;
                });
              }}
              columns={[
                {
                  Header: "_id",
                  accessor: "id"
                },
                {
                  Header: "username",
                  accessor: "username"
                },
                {
                  Header: "email",
                  accessor: "email"
                },
                {
                  Header: "color",
                  accessor: "color",
                  style: {
                    textAlign: "left"
                  },
                  Cell: function Color({ row }) {
                    const [display, setDisplay] = useState(false);
                    const [color, setColor] = useState(row.color);
                    console.log(row);
                    return (
                      <div className="actions-center">
                        <div
                          style={{
                            width: "100%"
                          }}
                          onClick={() => {
                            setDisplay(true);
                          }}
                        >
                          <div
                            className="actions-center"
                            style={{
                              margin: "0 auto",
                              width: "20px",
                              border: "1px solid",
                              height: "20px",
                              borderRadius: "50%",
                              background: `${
                                color
                                  ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
                                  : "rgba(0,0,0,0)"
                              }`
                            }}
                          />
                        </div>
                        {display ? (
                          <div
                            style={{
                              position: "absolute",
                              zIndex: "2"
                            }}
                          >
                            <div
                              style={{
                                position: "fixed",
                                top: "0px",
                                right: "0px",
                                bottom: "0px",
                                left: "0px"
                              }}
                              onClick={() => {
                                setDisplay(false);
                                console.log(row._original.userDataId, color);
                                updateAdvisor(row._original.userDataId, {
                                  color
                                });
                              }}
                            />
                            <SketchPicker
                              color={color}
                              onChange={(colorSelected, event) => {
                                setColor(colorSelected.rgb);
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    );
                  }
                },
                {
                  Header: "Rimuovi Advisor",
                  headerStyle: { textAlign: "left" },

                  Cell: function Fun(row) {
                    return (
                      <div className="actions-center">
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            props.updateAlert({
                              title: `Sicuro di voler rimuovere ${row.original.username} dagli advisor?`,
                              confirmBtnText: "Rimuovi advisor",
                              showCancel: true,
                              confirm: () => {
                                return updateAdvisor(row.original.userDataId, {
                                  permissions: "FOODIE",
                                  color: {}
                                }).then(data => {
                                  console.log(data);
                                  if (data) {
                                    props.updateAlert({
                                      title: "Successo",
                                      success: true
                                    });
                                    const user = [...users];
                                    user.splice(row.index, 1);
                                    setUsers(user);
                                  }
                                });
                              }
                            });
                          }}
                          color="danger"
                          className="remove"
                        >
                          <Close />
                        </Button>
                      </div>
                    );
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
