import React, { useState, useMemo, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Check from "@material-ui/icons/Check";
import moment from "moment";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

import getEditRequest from "api/restaurant/getEditRequest.js";
import manageEdit from "api/restaurant/manageEdit.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
function CustomSelect(value, menuType, classes, change) {
  console.log(value);
  return (
    <Select
      value={value}
      onChange={e => {
        change(e.target.value);
      }}
      MenuProps={{ className: classes.selectMenu }}
      classes={{ select: classes.select }}
      style={{ width: "100%" }}
    >
      {menuType.map(el => {
        return (
          <MenuItem
            key={el}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelectedMultiple
            }}
            value={el}
          >
            {el}
          </MenuItem>
        );
      })}
    </Select>
  );
}
const useStyles = makeStyles(styles);
export default function ReactTables() {
  const [status, setStatus] = useState("pending");
  const [restaurant, setRestaurant] = useState([]);
  const rederData = useMemo(() => {
    console.log(restaurant);
    return restaurant.map(prop => {
      let edit = {};
      if (prop.proposedEdit && prop.restaurantId) {
        Object.keys(prop.proposedEdit).forEach(k => {
          if (
            prop.proposedEdit[k] &&
            Array.isArray(prop.proposedEdit[k]) &&
            prop.restaurantId[k]
          ) {
            edit.new = (
              <div>
                <h6>{k}</h6>
                {prop.proposedEdit[k].map(e => (
                  <div>{e.label_it}</div>
                ))}
              </div>
            );
            edit.old = (
              <div>
                <h6>{k}</h6>
                {prop.restaurantId[k].map(e => (
                  <div>{e.label_it}</div>
                ))}
              </div>
            );
          }
          // edit.old = `${k}: ${prop.restaurantId[k]
          //   .map(e => e.label_it)
          //   .join(",")}`;
        });
      }

      console.log(edit);
      let type = "";
      let typeToSend = "";
      if (RegExp("^edit.*").test(prop.requestType)) {
        type = "MODIFICA";
        typeToSend = "edit";
      } else if (RegExp("^ceased.*").test(prop.requestType)) {
        type = "CHIUSURA";
        typeToSend = "ceased";
      } else if (RegExp("^duplicate.*").test(prop.requestType)) {
        type = "DUPLICATO";
        typeToSend = "duplicate";
      }
      return {
        _id: prop._id,
        ...edit,
        alias: prop.alias,
        typeToSend,
        type: type,
        date: moment(prop.creationDate).format("DD/MM/YYYY"),
        nome:
          prop.restaurantId && prop.restaurantId.name
            ? prop.restaurantId.name
            : "",
        username: prop.userId && prop.userId.username,
        alias:
          prop.restaurantId && prop.restaurantId.alias
            ? prop.restaurantId.alias
            : ""
      };
    });
  }, [restaurant]);
  useEffect(() => {
    getEditRequest(status).then(res => {
      console.log(res);

      setRestaurant(res.backoffice || []);
      return null;
    });
  }, [status]);
  console.log(rederData);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {"Lista Ristoranti Suggeriti"}
            </h4>
            <div style={{ width: "150px", float: "right" }}>
              {CustomSelect(
                status,
                ["pending", "fulfilled", "deleted"],
                classes,
                e => {
                  setStatus(e);
                }
              )}
            </div>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              columns={[
                {
                  Header: "date",
                  accessor: "date"
                },
                {
                  Header: "alias",
                  accessor: "alias",
                  Cell: function alias(row) {
                    return (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.foodiestrip.com/it/ristorante/${row.original.alias}`}
                      >
                        {row.row.alias}
                      </a>
                    );
                  }
                },
                {
                  Header: "nome",
                  accessor: "nome"
                },
                {
                  Header: "username",
                  accessor: "username"
                },
                {
                  Header: "tipo",
                  accessor: "type",
                  Cell: function Type({ row }) {
                    let color = "black";
                    if (row.type === "MODIFICA") {
                      color = "orange";
                    } else if (row.type === "CHIUSURA") {
                      color = "red";
                    } else if (row.type === "DUPLICATO") {
                      color = "blue";
                    }
                    return <div style={{ color }}>{row.type}</div>;
                  },
                  Filter: ({ filter, onChange }) => {
                    return CustomSelect(
                      filter ? filter.value : "ALL",
                      ["", "MODIFICA", "CHIUSURA", "DUPLICATO"],
                      classes,
                      onChange
                    );
                  }
                },
                {
                  Header: "vecchio",
                  accessor: "old",
                  filterable: false,
                  sortable: false
                  // Cell: function Old(row) {
                  //   return row.old;
                  // }
                },
                {
                  Header: "richiesta",
                  accessor: "new",
                  filterable: false,
                  sortable: false
                },
                {
                  Header: "funzioni",
                  headerStyle: {
                    textAlign: "left"
                  },
                  accessor: "",
                  sortable: false,
                  Filter: function Fil() {
                    return <div>{"edit/conferma/elimina"}</div>;
                  },
                  Cell: function Fun(row) {
                    return (
                      <div className="actions-center">
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            window.open(
                              `https://backoffice.foodiestrip.com/dashboard/?${row.original.alias}`
                            );
                          }}
                          color="info"
                          className="remove"
                        >
                          <Edit />
                        </Button>
                        {/* use this button to add a like kind of action */}
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            return manageEdit({
                              requestId: row.original._id,
                              status: "fulfilled",
                              type: row.original.typeToSend
                            }).then(() => {
                              const r = [].concat(restaurant);
                              r.splice(row.index, 1);
                              setRestaurant(r);
                            });
                          }}
                          color="success"
                          className="edit"
                        >
                          <Check />
                        </Button>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            return manageEdit({
                              requestId: row.original._id,
                              status: "deleted",
                              type: row.original.typeToSend
                            }).then(() => {
                              const r = [].concat(restaurant);
                              r.splice(row.index, 1);
                              setRestaurant(r);
                            });
                          }}
                          color="rose"
                          className="remove"
                        >
                          <Close />
                        </Button>
                      </div>
                    );
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  if (rowInfo.original.isLocked || rowInfo.original.isPending) {
                    return {
                      style: {
                        background: "yellow"
                      }
                    };
                  }
                }
                return {};
              }}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
