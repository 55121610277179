import React, { useState, useEffect, useMemo } from "react";
import "assets/scss/material-dashboard-pro-react/plugins/_plugin-react-datepicker.css";
import moment from "moment";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DatePicker from "react-datepicker";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import getCertified from "api/certified/getCertified.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const [data, setData] = useState([]);
  const [c] = useState(false);
  const rederData = useMemo(
    () =>
      data.map(prop => {
        return {
          alias: prop.alias,
          name: prop.name,
          date: moment(prop.certificationRequest.date).format("DD/MM/YYYY"),
          username:
            prop.certificationRequest && prop.certificationRequest.advisorName
              ? prop.certificationRequest.advisorName
              : prop.certificationRequest.userId.username,
          city: prop.addressIT.admin3,
          checkIn: prop.checkInNumber || 0,
          recensioni: prop.reviewNumber || 0,
          foto: prop.photoNumber || 0
        };
      }),
    [data]
  );
  useEffect(() => {
    getCertified().then(a => {
      setData(a.restaurants);
      return a.restaurants;
    });
  }, [c]);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              Lista Certificati {rederData.length}
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              columns={[
                {
                  Header: "alias",
                  accessor: "alias"
                },
                {
                  Header: "username",
                  accessor: "username"
                },
                {
                  Header: "name",
                  accessor: "name"
                },
                // {
                //   Header: "reg",
                //   accessor: "registrato",
                //   Filter: ({ filter, onChange }) => {
                //     const start =
                //       filter && filter.value && filter.value.start
                //         ? filter.value.start
                //         : new Date("2016-01-01");
                //     const end =
                //       filter && filter.value && filter.value.end
                //         ? filter.value.end
                //         : new Date();
                //     return datePicker(start, end, filter, onChange);
                //   }
                // },
                {
                  Header: "city",
                  accessor: "city"
                },
                {
                  Header: "data",
                  accessor: "date"
                },
                {
                  Header: "check",
                  accessor: "checkIn"
                },
                {
                  Header: "rev",
                  accessor: "recensioni"
                },
                {
                  Header: "foto",
                  accessor: "foto",
                  headerStyle: { textAlign: "left" },

                  style: {
                    textAlign: "left"
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
