import AdvisorList from "view2/AdvisorList.js";

import connectFunction from "redux/connectFunction";

function mapStateToProps(state) {
  return {
    alert: state.alert
  };
}

const AdvisorListContainer = connectFunction(mapStateToProps)(AdvisorList);
export default AdvisorListContainer;
