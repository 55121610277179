import React, { useState, useEffect, useMemo } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import parseSearch from "util/parseSearch.js";
import _ from "lodash";
// core components
import Button from "components/CustomButtons/Button.js";
import Assignment from "@material-ui/icons/Assignment";
import CustomInput from "components/CustomInput/CustomInput.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import reviewDetails from "api/review/reviewDetail.js";
import updateReview from "api/review/updateReview.js";
// import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
const useStyles = makeStyles(styles);

export function EditableInput(prop) {
  const [value, setValue] = useState(prop.field);
  let formControlProps = { fullWidth: true };
  if (prop.formcontrolprops) {
    Object.assign(formControlProps, prop.formcontrolprops);
  }
  return (
    <div>
      <CustomInput
        formControlProps={{ fullWidth: true, style: { margin: 0, padding: 0 } }}
        inputProps={{
          value: value,
          // onBlur: e => {
          //   console.log(e, value);
          // },
          onChange: e => {
            setValue(e.target.value);
          },
          ...prop
        }}
      />
    </div>
  );
}

export default function ReviewAnalytics() {
  const search = window.location.search;
  const query = parseSearch(search);
  const [review, setReview] = useState({});
  let reviewId = null;
  if (query && query.id) {
    reviewId = query.id;
  }
  let scoreCalculated = [];
  const [scores, setScores] = useState([]);

  const rederData = useMemo(() => {
    if (review && review.trip) {
      // const score = review.score.reduce(function(acc, cur, i) {
      //   acc[cur.name] = cur.score;
      //   return acc;
      // }, {});

      return {
        _id: review._id,
        // categoria: review.category.id,
        name:
          review.restaurantId && review.restaurantId.name
            ? review.restaurantId.name
            : "",
        username:
          review.userId && review.userId.username ? review.userId.username : "",
        score: review.score,
        trip: review.trip.map(el => {
          if (!el.reference) {
            return {
              question:
                el.question && el.question.it && el.question.it.question
                  ? el.question.it.question
                  : "",
              answers: [{ idAnswer: "", text: el.logic }],
              score: el.score,
              weight: el.weight
            };
          }
          const logic = el.reference.logic.replace(/\W/g, "");
          const newLogic = logic.split("");
          console.log(newLogic);
          const ans = el.question.it.answers;
          const answers = newLogic.map(l => ans.find(a => l === a.idAnswer));
          console.log(answers);
          return {
            question:
              el.question && el.question.it && el.question.it.question
                ? el.question.it.question
                : "",
            answers,
            score: el.reference.score,
            weight: el.weight
          };
        })
      };
    }
    return {};
  }, [review]);
  useEffect(() => {
    reviewDetails(reviewId).then(a => {
      console.log(a);
      setReview(a.review);
      setScores(
        a.review.trip.map(el => ({
          evaluationType: el.evaluationType,
          score: el.reference ? el.reference.score : el.score,
          weight: el.weight
        }))
      );
      return null;
    });
  }, []);

  scoreCalculated = useMemo(() => {
    // scoreCalculated = scores.reduce((arr,curr)=>{
    //
    // })
    if (!scores || scores.length === 0) {
      return [];
    }
    const unique = _.uniq(
      [].concat(...scores.map(e => e.evaluationType)).concat(null)
    );

    return unique.map(u => {
      const filtered = scores.filter(e =>
        u && e.evaluationType ? e.evaluationType.includes(u) : true
      );
      const sc = filtered.reduce(
        (a, b) => {
          a.score += b.score;
          a.weight += b.weight;
          a.sXw += b.score * b.weight;
          return a;
        },
        { score: 0, weight: 0, sXw: 0 }
      );
      if (!u) {
        u = "total";
      }
      let oldScore = rederData.score.find(e => e.name === u);
      oldScore = oldScore ? oldScore.score : 0;
      return { name: u, score: sc.sXw / sc.weight, oldScore };
    });
  }, [scores]);
  console.log(scoreCalculated);
  const classes = useStyles();
  return (
    <GridContainer style={{ position: "relative" }}>
      {/* <GridItem container xs={12} style={{ float: "left" }}>
        {scoreCalculated.map((a, i) => {
          if (
            a.name === "total" ||
            a.name === "ambience" ||
            a.name === "service" ||
            a.name === "menu"
          ) {
            return (
              <GridItem key={i.toString()} xs={3}>
                <Card>
                  <CardHeader color="">
                    <h4 className={classes.cardIconTitle}>{a.name + ": "}</h4>
                  </CardHeader>
                  <CardBody>
                    <GridItem>
                      <h6 className={classes.cardIconTitle}>Nuovo/Vecchio</h6>
                    </GridItem>
                    <GridItem>
                      <span style={{ color: "red" }}>{a.score.toFixed(2)}</span>
                      {"/" + a.oldScore}
                    </GridItem>
                  </CardBody>
                </Card>
              </GridItem>
            );
          }
          return null;
        })}
      </GridItem> */}

      <GridItem xs={8}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{rederData._id}</h4>
          </CardHeader>
          <CardBody>
            {rederData && rederData.trip
              ? rederData.trip.map(function RenderTrip(r, i) {
                  return (
                    <Card key={r.question}>
                      <CardHeader>
                        <h4 className={classes.cardIconTitle}>{r.question}</h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem container xs={12} style={{ float: "left" }}>
                            {r.answers.map(a => {
                              console.log(a);
                              if (a) {
                                return (
                                  <GridItem key={a.text} xs={12}>
                                    {a.idAnswer + ": " + a.text}
                                  </GridItem>
                                );
                              }
                              return null;
                            })}
                          </GridItem>
                        </GridContainer>

                        <GridItem xs={1} md={6}></GridItem>
                        <GridItem
                          container
                          xs={11}
                          md={6}
                          style={{ float: "right" }}
                        >
                          <GridItem xs={6}>
                            <GridItem xs={12}>{"score"}</GridItem>
                            <GridItem xs={6}>
                              <EditableInput
                                field={r.score}
                                inputProps={{ style: { textAlign: "center" } }}
                                onBlur={e => {
                                  const s = [].concat(scores);
                                  s[i].score = Number(e.target.value);
                                  const rev = Object.assign({}, review);
                                  console.log(rev);
                                  if (rev.trip[i].reference) {
                                    rev.trip[i].reference.score = Number(
                                      e.target.value
                                    );
                                  }
                                  rev.trip[i].score = Number(e.target.value);

                                  console.log("ckemwopwemfopmwefpwfmeo", rev);
                                  setScores(s);
                                  setReview(rev);
                                }}
                              />
                            </GridItem>
                          </GridItem>

                          <GridItem xs={6}>
                            <GridItem xs={12}>{"weight"}</GridItem>
                            <GridItem xs={6}>
                              <EditableInput
                                field={r.weight}
                                // formControlProps={{ }}
                                inputProps={{ style: { textAlign: "center" } }}
                                onBlur={e => {
                                  const s = [].concat(scores);
                                  s[i].weight = Number(e.target.value);
                                  console.log(s);
                                  const rev = Object.assign({}, review);
                                  console.log(rev);

                                  rev.trip[i].weight = Number(e.target.value);

                                  console.log("ckemwopwemfopmwefpwfmeo", rev);
                                  setScores(s);
                                  setReview(rev);
                                }}
                              />
                            </GridItem>
                          </GridItem>
                        </GridItem>
                      </CardBody>
                    </Card>
                  );
                })
              : null}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        xs={4}
        style={{
          position: "fixed",
          right: "15px",
          width: "calc(33% - 80px)"
        }}
      >
        {scoreCalculated ? (
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Calcolati</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem container xs={12} style={{ float: "left" }}>
                  {scoreCalculated.map(a => (
                    <GridItem key={a.score + a.name} xs={12}>
                      {a.name + ": "}
                      <span style={{ color: "red" }}>{a.score.toFixed(2)}</span>
                      {"/" + a.oldScore}
                    </GridItem>
                  ))}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        ) : null}
      </GridItem>
      <GridItem
        xs={4}
        style={{
          position: "fixed",
          right: "15px",
          bottom: "30px"
          // width: "calc(33% - 80px)"
        }}
      >
        <Button
          round
          color="danger"
          onClick={() => {
            const rev = Object.assign({}, review);
            rev.score = scoreCalculated.map(el => ({
              name: el.name,
              score: el.score
            }));
            updateReview(rev);
          }}
        >
          Salva
        </Button>
      </GridItem>
    </GridContainer>
  );
}
