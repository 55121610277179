import React, { useState, useEffect } from "react";
// grafica
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";

import CardFooter from "components/Card/CardFooter.js";
//Stile
import { makeStyles } from "@material-ui/core/styles";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
//icone
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);
export default function CardBasic(props) {
  const classes = useStyles();
  const { icon, title, body, footer } = props;
  return (
    <Card>
      <CardHeader color="primary" icon>
        <CardIcon color="primary">{icon}</CardIcon>
        <h4 className={classes.cardIconTitle}>{title}</h4>
      </CardHeader>
      <CardBody>{body}</CardBody>
      <CardFooter>{footer}</CardFooter>
    </Card>
  );
}
