import React, { useState, useEffect, useMemo } from "react";
import "assets/scss/material-dashboard-pro-react/plugins/_plugin-react-datepicker.css";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

import html2canvas from "html2canvas";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DatePicker from "react-datepicker";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardText from "components/Card/CardText.js";
import Check from "@material-ui/icons/Check";

import Close from "@material-ui/icons/Close";

import Button from "components/CustomButtons/Button.js";

import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import getImage from "api/photo/getImage.js";
import approvePhotos from "api/photo/approvePhoto.js";
import resolveAbuse from "api/photo/resolveAbuse.js";

Object.assign(styles, {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  restaurantName: {
    textAlign: "center"
  }
});

const useStyles = makeStyles(styles);
let refs = [];
export default function ReactTables() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("all");

  // const rederData = useMemo(
  //   () =>
  //     data.map(prop => {
  //       return {
  //         alias: prop.alias,
  //         name: prop.name,
  //         date: prop.certificationRequest.date,
  //         username:
  //           prop.certificationRequest && prop.certificationRequest.advisorName
  //             ? prop.certificationRequest.advisorName
  //             : prop.certificationRequest.userId.username,
  //         city: prop.addressIT.admin3,
  //         checkIn: prop.checkInNumber || 0,
  //         recensioni: prop.reviewNumber || 0,
  //         foto: prop.photoNumber || 0
  //       };
  //     }),
  //   [data]
  // );
  useEffect(() => {
    getImage(filter).then(a => {
      console.log(a.photos);
      setData(a.photos);
      return a;
    });
  }, [filter]);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{ display: "table", margin: "0 auto 20px auto" }}
          className={classes.buttonGroup}
        >
          <Button
            color="info"
            onClick={() => {
              setFilter("all");
            }}
            round
            className={classes.firstButton}
          >
            da approvare
          </Button>

          <Button
            color="danger"
            round
            className={classes.lastButton}
            onClick={() => {
              setFilter("abuse");
            }}
          >
            segnalate
          </Button>
        </div>
        {filter === "abuse" ? (
          <div
            style={{ display: "table", margin: "0 auto 20px auto" }}
            className={classes.buttonGroup}
          >
            <div style={{ color: "green", float: "left" }}>
              <Check />
              {"accetta la segnalazione/photo non accettata"}
            </div>

            <div style={{ color: "red", float: "left" }}>
              <Close />
              {"rifiuta la segnalazione/photo accettata"}
            </div>
          </div>
        ) : null}
      </GridItem>
      <GridItem xs={12}></GridItem>
      {data.map((el, i) => {
        return (
          <GridItem key={el._id} xs={4}>
            <Card>
              <CardHeader color="primary" icon>
                <div style={{ display: "inline-block", width: "100%" }}>
                  <div style={{ float: "left" }}>
                    <CardAvatar
                      profile
                      className={classes.cardAvatar}
                      style={{
                        margin: 0,
                        maxWidth: "50px",
                        maxHeight: "50px",

                        position: "absolute",
                        left: "-25px",
                        top: "-30px"
                      }}
                    >
                      <img
                        style={{ width: "100%" }}
                        src={el.userId.profileImageUrl}
                      />
                    </CardAvatar>

                    <div style={{ marginLeft: "10px" }}>
                      <h5 className={classes.cardIconTitle}>
                        {"Scattata da: "}
                      </h5>
                      <h5 className={classes.cardIconTitle}>
                        {el.userId.username}
                      </h5>
                    </div>
                  </div>
                  {el.abuse && el.abuse.userId ? (
                    <div style={{ float: "right" }}>
                      <CardAvatar
                        profile
                        className={classes.cardAvatar}
                        style={{
                          margin: 0,
                          maxWidth: "50px",
                          maxHeight: "50px",

                          position: "absolute",
                          right: "-25px",
                          top: "-30px"
                        }}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={el.abuse.userId.profileImageUrl}
                        />
                      </CardAvatar>
                      <div style={{ marginRight: "10px" }}>
                        <h5 className={classes.cardIconTitle}>
                          {"Segnalata da: "}
                        </h5>
                        <h5 className={classes.cardIconTitle}>
                          {el.abuse.userId.username}
                        </h5>
                        {/* <h5 className={classes.cardIconTitle}>
                          {el.description}
                        </h5> */}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={classes.restaurantName}>
                  {/* <h5 className={classes.cardIconTitle}>
                    {"nome Ristorante: "}
                  </h5> */}
                  <h5 className={classes.cardIconTitle}>
                    {el.restaurantId.name}
                  </h5>
                </div>
                {el.abuse && el.abuse.userId ? (
                  <h3
                    style={{ color: "red" }}
                    className={classes.cardIconTitle}
                  >
                    {el.abuse.type}
                  </h3>
                ) : null}
                <h5 className={classes.cardIconTitle}>{el.description}</h5>
              </CardHeader>
              <CardBody>
                <img
                  id={i}
                  ref={e => {
                    refs[i] = e;
                  }}
                  style={{
                    width: "100%"
                    // transform: `rotate(${rotation[i]}deg)`
                  }}
                  src={el.url}
                />
              </CardBody>
              <CardFooter>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    if (filter === "abuse") {
                      return resolveAbuse({
                        status: "accepted",
                        id: el._id,
                        approved: false,
                        duplicate: el.abuse.type === "duplicate"
                      }).then(r => {
                        if (r) {
                          const dat = [].concat(data);
                          dat.splice(i, 1);
                          setData(dat);
                        }
                      });
                    }
                    return approvePhotos({ id: el._id, approved: true }).then(
                      () => {
                        const dat = [].concat(data);
                        dat.splice(i, 1);
                        setData(dat);
                      }
                    );

                    // const rot = [].concat(rotation);
                    // if (!rot[i]) {
                    //   rot[i] = 0;
                    // }
                    // rot[i] = rot[i] + 90;
                    // setRotation(rot);
                  }}
                  // style={{ width: "60px", height: "60px", marginTop: "-25px" }}
                  style={{ margin: "-20px 0px 0px 0" }}
                  size={"lg"}
                  color="success"
                  className="edit"
                >
                  <Check />
                </Button>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    if (filter === "abuse") {
                      return resolveAbuse({
                        status: "deleted",
                        id: el._id,
                        approved: true,
                        duplicate: el.type === "duplicate"
                      }).then(() => {
                        const dat = [].concat(data);
                        dat.splice(i, 1);
                        setData(dat);
                      });
                    }
                    return approvePhotos({ id: el._id, approved: false }).then(
                      () => {
                        const dat = [].concat(data);
                        dat.splice(i, 1);
                        setData(dat);
                      }
                    );
                  }}
                  style={{ margin: "-20px 0px 0px 0" }}
                  size={"lg"}
                  color="rose"
                  className="remove"
                >
                  <Close />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
  );
}
