import React, { useState, useMemo } from "react";
import config from "config/config.js";

// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import DatePicker from "react-datepicker";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Check from "@material-ui/icons/Check";
import moment from "moment";
import Search from "@material-ui/icons/Search";
import Visibility from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";

import checkInList from "api/checkIn/checkInList.js";
import deleteCheckIn from "api/checkIn/deleteCheckIn.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

function datePicker(start, end, filter, onChange) {
  return (
    <>
      <DatePicker
        style={{ float: "left", width: "50%" }}
        selected={start}
        onChange={date => {
          const fil = filter && filter.value ? filter.value : {};
          fil.start = date;
          onChange(fil);
        }}
        selectsStart
        dateFormat="MM/yyyy"
        showMonthYearPicker
        startDate={start}
        endDate={end}
      />
      <DatePicker
        selected={end}
        onChange={date => {
          const fil = filter && filter.value ? filter.value : {};
          fil.end = date;
          onChange(fil);
        }}
        selectsEnd
        dateFormat="MM/yyyy"
        showMonthYearPicker
        endDate={end}
        startDate={start}
        minDate={start}
      />
    </>
  );
}
function customSelect(value, menuType, classes, change) {
  return (
    <Select
      value={value}
      onChange={e => {
        change(e.target.value);
      }}
      MenuProps={{ className: classes.selectMenu }}
      classes={{ select: classes.select }}
      style={{ width: "100%" }}
    >
      {menuType.map(el => {
        return (
          <MenuItem
            key={el}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelectedMultiple
            }}
            value={el}
          >
            {el}
          </MenuItem>
        );
      })}
    </Select>
  );
}
let filterState = {};
export default function ReactTables(props) {
  const [checkIn, setCheckIn] = useState([]);

  const rederData = useMemo(
    () =>
      checkIn.map(prop => {
        return {
          _id: prop._id,

          nome: prop.restaurantId ? prop.restaurantId.name : "",
          status: prop.status,
          alias: prop.restaurantId ? prop.restaurantId.alias : "",
          username: prop.userId ? prop.userId.username : "",
          finishDate: prop.finishDate,
          checkInTime: moment(prop.finishDate).diff(
            moment(prop.startDate),
            "minutes"
          ),
          reviewed: !!prop.reviewed
        };
      }),
    [checkIn]
  );

  // const [sort, setSort] = useState([]);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Lista check-in</h4>
          </CardHeader>
          <CardBody>
            <Button
              color="white"
              aria-label="edit"
              justIcon
              round
              style={{
                position: "absolute",
                zIndex: 999,
                right: "36px",
                top: "12px"
              }}
              onClick={() => {
                checkInList(filterState).then(a => {
                  setCheckIn(a.checkIns);
                  return a.users;
                });
              }}
            >
              <Search
                className={classes.headerLinksSvg + " " + classes.searchIcon}
              />
            </Button>

            <ReactTable
              data={rederData}
              filterable
              // onSortedChange={newSorted => {
              //   setSort(newSorted);
              // }}
              onFetchData={state => {
                // if (JSON.stringify(sort) !== JSON.stringify(state.sorted)) {
                //   return null;
                // }
                const arrayToObject = array =>
                  array.reduce((obj, item) => {
                    obj[item.id] = item.value;
                    return obj;
                  }, {});
                const filter = arrayToObject(state.filtered);
                filterState = filter;
                if (filterState && Object.keys(filterState).length === 0) {
                  checkInList(filter).then(a => {
                    setCheckIn(a.checkIns);
                    return a.users;
                  });
                }
              }}
              columns={[
                {
                  Header: "Alias",
                  accessor: "alias",
                  Cell: function alias(row) {
                    return (
                      <a
                        href={`https://www.foodiestrip.com/it/ristorante/${row.original.alias}`}
                      >
                        {row.row.alias}
                      </a>
                    );
                  },
                  filterMethod: () => true
                },
                {
                  Header: "nome",
                  accessor: "nome",
                  filterMethod: () => true
                },
                {
                  Header: "status",
                  accessor: "status",
                  Filter: ({ filter, onChange }) => {
                    return customSelect(
                      filter ? filter.value : "ALL",
                      ["", "fulfilled", "rejected", "deleted", "aborted"],
                      classes,
                      onChange
                    );
                  },
                  filterMethod: () => true
                },
                {
                  Header: "finishDate",
                  accessor: "finishDate",
                  Filter: ({ filter, onChange }) => {
                    const start =
                      filter && filter.value && filter.value.start
                        ? filter.value.start
                        : new Date("2016-01-01");
                    const end =
                      filter && filter.value && filter.value.end
                        ? filter.value.end
                        : new Date();
                    return datePicker(start, end, filter, onChange);
                  },
                  filterMethod: () => true
                },

                {
                  Header: "checkInTime",
                  accessor: "checkInTime",
                  filterable: false,
                  filterMethod: () => true
                },
                {
                  Header: "username",
                  accessor: "username",
                  filterMethod: () => true
                },
                {
                  Header: "reviewed",
                  accessor: "reviewed",
                  headerStyle: {
                    textAlign: "left"
                  },
                  Cell: function C({ row }) {
                    return (
                      <>
                        {row.reviewed ? (
                          <div style={{ float: "left", margin: "10px" }}>
                            <Check className={classes.checkedIcon} />
                            <Visibility
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                window.open(
                                  `${config.baseUrl}/admin/reviewAnalytics?id=${checkIn[row._index].reviewed}`
                                );
                              }}
                              className={
                                classes.headerLinksSvg +
                                " " +
                                classes.searchIcon
                              }
                            />
                          </div>
                        ) : null}
                        <Button
                          round
                          justIcon
                          simple
                          style={{ float: "right" }}
                          // className={classes.right}
                          color="danger"
                          onClick={() => {
                            props.updateAlert({
                              title: "Sicuro di voler cancellare il checkIn?",
                              confirmBtnText: "Cancella checkIn",
                              showCancel: true,
                              confirm: () => {
                                return deleteCheckIn(row._original._id).then(
                                  response => {
                                    if (response && response.message) {
                                      props.updateAlert({
                                        title: "successo",
                                        success: true
                                      });

                                      const user = [...checkIn];
                                      user.splice(row._index, 1);
                                      setCheckIn(user);
                                    }
                                  }
                                );
                              }
                            });
                          }}
                        >
                          <Close />
                        </Button>
                      </>
                    );
                  },
                  Filter: ({ filter, onChange }) => {
                    return customSelect(
                      filter ? filter.value : "ALL",
                      ["", "true", "false"],
                      classes,
                      onChange
                    );
                  },
                  filterMethod: () => true
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
