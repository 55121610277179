import AddRestaurant from "view2/AddRestaurant.js";

import connectFunction from "redux/connectFunction";

function mapStateToProps(state) {
  return {
    categories: state.categories,
    aler: state.alert
  };
}

const AddRestaurantContainer = connectFunction(mapStateToProps)(AddRestaurant);
export default AddRestaurantContainer;
