import Cookies from "cookies-js";

const FOODIES_COOKIE_NAME = "foodies";

export default function getFreshToken() {
  const foodiesCookieValue = Cookies.get(FOODIES_COOKIE_NAME); // get the cookie value
  let foodiesToken = null;
  try {
    foodiesToken = JSON.parse(foodiesCookieValue);
  } catch (e) {
    // console.log('No cookie to parse');
    return null;
  }
  if (foodiesToken) {
    return foodiesToken.token;
  } else {
    return null;
  }
}
