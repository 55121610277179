import React, { useState, useEffect, useMemo } from "react";
import "assets/scss/material-dashboard-pro-react/plugins/_plugin-react-datepicker.css";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";

import Close from "@material-ui/icons/Close";

import Button from "components/CustomButtons/Button.js";

import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import getBlobs from "api/blob/getBlobs.js";
import approveBlob from "api/blob/approveBlob.js";
import resolveAbuse from "api/blob/resolveAbuse.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

Object.assign(styles, {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  restaurantName: {
    textAlign: "center"
  },
  score: {
    borderRadius: "50%",
    margin: "0 auto",
    border: "1px solid",
    width: "40px",
    height: "40px",
    lineHeight: "30px",
    padding: "5px"
  },
  avatar: {
    margin: 0,
    maxWidth: "50px",
    maxHeight: "50px",

    position: "absolute",
    right: "-25px",
    top: "-30px"
  },
  avatarLeft: {
    left: "-25px",
    top: "-30px"
  },
  avatarRight: {
    right: "-25px",
    top: "-30px"
  }
});

const useStyles = makeStyles(styles);

export function EditableInput(prop) {
  const [value, setValue] = useState(prop.field);
  let formControlProps = { fullWidth: true };
  if (prop.formcontrolprops) {
    Object.assign(formControlProps, prop.formcontrolprops);
  }
  return (
    <div>
      <CustomInput
        formControlProps={formControlProps}
        inputProps={{
          value: value,
          // onBlur: e => {
          //   console.log(e, value);
          // },
          onChange: e => {
            setValue(e.target.value);
          },
          ...prop
        }}
      />
    </div>
  );
}

export default function ReactTables() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    getBlobs(filter).then(a => {
      console.log(a);
      setData(a.blob);
      return a;
    });
  }, [filter]);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{ display: "table", margin: "0 auto 20px auto" }}
          className={classes.buttonGroup}
        >
          <Button
            color="info"
            onClick={() => {
              setFilter("all");
            }}
            round
            className={classes.firstButton}
          >
            da approvare
          </Button>

          <Button
            color="danger"
            round
            className={classes.lastButton}
            onClick={() => {
              setFilter("abuse");
            }}
          >
            segnalate
          </Button>
        </div>
        {filter === "abuse" ? (
          <div
            style={{ display: "table", margin: "0 auto 20px auto" }}
            className={classes.buttonGroup}
          >
            <div style={{ color: "green", float: "left" }}>
              <Check />
              {"accetta la segnalazione/Blob non accettato"}
            </div>

            <div style={{ color: "red", float: "left" }}>
              <Close />
              {"rifiuta la segnalazione/blob accettato"}
            </div>
          </div>
        ) : null}
      </GridItem>
      <GridItem xs={12}></GridItem>
      {data.map((el, i) => {
        return (
          <GridItem key={el._id} xs={6}>
            <Card>
              <CardHeader color="primary" icon>
                <div style={{ display: "inline-block", width: "100%" }}>
                  <div style={{ float: "left" }}>
                    <CardAvatar
                      profile
                      className={
                        classes.cardAvatar +
                        " " +
                        classes.avatarLeft +
                        " " +
                        classes.avatar
                      }
                    >
                      <img
                        style={{ width: "100%" }}
                        src={el.userId.profileImageUrl}
                      />
                    </CardAvatar>

                    <div style={{ marginLeft: "10px" }}>
                      <h5 className={classes.cardIconTitle}>
                        {"Scritto da: "} <b>{el.userId.username}</b>
                      </h5>
                      <h5 className={classes.cardIconTitle}></h5>
                    </div>
                  </div>
                  {el.abuse && el.abuse.userId ? (
                    <div style={{ float: "right" }}>
                      <CardAvatar
                        profile
                        className={
                          classes.cardAvatar +
                          " " +
                          classes.avatarRight +
                          " " +
                          classes.avatar
                        }
                      >
                        <img
                          style={{ width: "100%" }}
                          src={el.abuse.userId.profileImageUrl}
                        />
                      </CardAvatar>
                      <div style={{ marginRight: "10px" }}>
                        <h5 className={classes.cardIconTitle}>
                          {"Segnalata da: "}
                          <b>{el.abuse.userId.username}</b>
                        </h5>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className={classes.restaurantName}>
                  <h5 className={classes.cardIconTitle}>
                    {"ristorante: "}
                    <b>{el.restaurantId ? el.restaurantId.name : ""}</b>
                  </h5>
                </div>
                {el.abuse && el.abuse.userId ? (
                  <h3
                    style={{ color: "red" }}
                    className={classes.cardIconTitle}
                  >
                    {el.abuse.type}
                  </h3>
                ) : null}
              </CardHeader>
              <CardBody>
                <h4
                  style={{
                    width: "100%"
                  }}
                >
                  {el.text}
                </h4>
                <GridItem container justify="center">
                  {el.reviewId && el.reviewId.score
                    ? el.reviewId.score.map(item => (
                        <GridItem
                          key={item._id}
                          xs={12 / el.reviewId.score.length}
                        >
                          <div className={classes.score}>
                            {item.score.toFixed(2) === "10.00"
                              ? "10.0"
                              : item.score.toFixed(2)}
                          </div>
                          <div className={classes.restaurantName}>
                            {item.name}
                          </div>
                        </GridItem>
                      ))
                    : null}
                </GridItem>
              </CardBody>
              <CardFooter>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    if (filter === "abuse") {
                      return resolveAbuse({
                        status: "accepted",
                        id: el._id,
                        approved: false,
                        duplicate: el.abuse.type === "duplicate"
                      }).then(r => {
                        if (r) {
                          const dat = [].concat(data);
                          dat.splice(i, 1);
                          setData(dat);
                        }
                      });
                    }
                    return approveBlob({ id: el._id, approved: true }).then(
                      () => {
                        const dat = [].concat(data);
                        dat.splice(i, 1);
                        setData(dat);
                      }
                    );
                  }}
                  style={{ margin: "-20px 0px 0px 0" }}
                  size={"lg"}
                  color="success"
                  className="edit"
                >
                  <Check />
                </Button>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    if (filter === "abuse") {
                      return resolveAbuse({
                        status: "deleted",
                        id: el._id,
                        approved: true,
                        duplicate: el.type === "duplicate"
                      }).then(() => {
                        const dat = [].concat(data);
                        dat.splice(i, 1);
                        setData(dat);
                      });
                    }
                    return approveBlob({ id: el._id, approved: false }).then(
                      () => {
                        const dat = [].concat(data);
                        dat.splice(i, 1);
                        setData(dat);
                      }
                    );
                  }}
                  style={{ margin: "-20px 0px 0px 0" }}
                  size={"lg"}
                  color="rose"
                  className="remove"
                >
                  <Close />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
  );
}
