import CheckInList from "container/CheckInListContainer.js";
import ReviewList from "container/ReviewListContainer.js";
import AddRestaurantContainer from "container/AddRestaurantContainer.js";
import AdvisorList from "container/AdvisorListContainer.js";
import UserList from "container/UserListContainer.js";
import OwnerPendingList from "view2/OwnerPendingList.js";

import ReviewAnalytics from "view2/ReviewAnalytics.js";
import OwnerList from "view2/OwnerList.js";
import CertificationList from "view2/CertificationList.js";
import SuggestedRestaurant from "view2/SuggestedRestaurant.js";
import EditRequest from "view2/EditRequest.js";
import Image from "view2/Image.js";
import Blob from "view2/BlobConfirmation.js";
import Billing from "view2/Billing.js";

import ChangePosition from "view2/ChangePosition.js";
import MergeAlias from "view2/MergeAlias.js";
import AliasRequest from "view2/AliasRequest.js";
// @material-ui/icons
import UserListIcon from "@material-ui/icons/Person";
import OwnerListIcon from "@material-ui/icons/Assignment";
import CertificationIcon from "@material-ui/icons/Stars";
import Restaurant from "@material-ui/icons/LocalDining";
import Activity from "@material-ui/icons/Check";
import UserActivity from "@material-ui/icons/Create";
import Advisor from "@material-ui/icons/PeopleTwoTone";
import CreditCardIcon from "@material-ui/icons/CreditCard";
// import ContentPaste from "@material-ui/icons/ContentPaste";
import Settings from "@material-ui/icons/Settings";

var attivita = [
  {
    path: "/checkIn",
    name: "Check-in",
    mini: "C",
    component: CheckInList,
    layout: "/admin"
  },
  {
    path: "/review",
    name: "Recensioni",
    mini: "R",
    component: ReviewList,
    layout: "/admin"
  }
];

var alias = [
  {
    path: "/merge",
    name: "Accorpa con alias",
    mini: "A",
    component: MergeAlias,
    layout: "/admin"
  },
  {
    path: "/alias-approve",
    name: "Approva cambio alias",
    mini: "Ac",
    component: AliasRequest,
    layout: "/admin"
  }
];

var attivitaUtente = [
  {
    path: "/owner-pending",
    name: "Owner Bloccati",
    mini: "OB",
    component: OwnerPendingList,
    layout: "/admin"
  },
  {
    path: "/suggested-restaurant",
    name: "Ristoranti Suggeriti",
    mini: "RS",
    component: SuggestedRestaurant,
    layout: "/admin"
  },
  {
    path: "/suggested-modify",
    name: "Richieste di Modifica",
    mini: "RM",
    component: EditRequest,
    layout: "/admin"
  },
  {
    path: "/position-change",
    name: "Cambio Posizione",
    mini: "CP",
    component: ChangePosition,
    layout: "/admin"
  },
  {
    path: "/immage",
    name: "Immagini",
    mini: "I",
    component: Image,
    layout: "/admin"
  },
  {
    path: "/blob",
    name: "Blob",
    mini: "B",
    component: Blob,
    layout: "/admin"
  }
  // {
  //   path: "/user-signaled",
  //   name: "Utenti Segnalati",
  //   mini: "US",
  //   component: UserProfile,
  //   layout: "/admin"
  // }
];

var dashRoutes = [
  {
    path: "/user-list",
    name: "Gestione Utenti",
    icon: UserListIcon,
    component: UserList,
    layout: "/admin"
  },
  {
    path: "/billing",
    name: "Billing",
    icon: CreditCardIcon,
    component: Billing,
    layout: "/admin"

    // layout: "/admin"
  },
  {
    path: "/owner-list",
    name: "Lista Owner",
    icon: OwnerListIcon,
    component: OwnerList,
    layout: "/admin"
  },
  {
    path: "/certification",
    name: "Certificazione",
    icon: CertificationIcon,
    component: CertificationList,
    layout: "/admin"
  },
  {
    path: "/advisor",
    name: "Lista advisor",
    icon: Advisor,
    component: AdvisorList,
    layout: "/admin"
  },
  {
    path: "/add-restaurant",
    name: "Aggiungi ristorante",
    icon: Restaurant,
    component: AddRestaurantContainer,
    layout: "/admin"
  },
  {
    collapse: true,
    path: "-activity",
    name: "Elenco Attività",
    state: "activity",
    icon: Activity,
    views: attivita
  },
  {
    collapse: true,
    path: "/components",
    name: "Attività Utenti",
    state: "attivitàUtente",
    icon: UserActivity,
    views: attivitaUtente
  },
  {
    collapse: true,
    path: "/alias",
    name: "Alias",
    state: "alias",
    icon: Settings,
    views: alias
  },

  {
    path: "/reviewAnalytics",
    component: ReviewAnalytics,
    layout: "/admin"

    // layout: "/admin"
  },

  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default dashRoutes;
