import config from "config/config.js";
import getFreshToken from "api/xsrfToken.js";

const getUsersList = filter => {
  // const endpoint = `${config.baseUrl}/api/tb/restaurants/update`;
  // const endpoint = "https://www.foodiestrip.com/api/tb/restaurants/update";
  const xsrfToken = getFreshToken();
  const endpoint = `${config.baseUrl}/api/photo/${filter}`;
  const options = {
    method: "GET",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "${config.baseUrl}",
      "Access-Control-Allow-Credentials": true,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": xsrfToken
    },
    credentials: "include"
  };
  return fetch(endpoint, options)
    .then(response => response.json())
    .then(response => {
      // eslint-disable-next-line no-console
      if (response.error && response.error.status && response.error.status === 401) {
        window.location.href = config.login
      }
      return response;
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log("errore", e);
    });
};

export default getUsersList;
