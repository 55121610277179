const configuration = {
  development: {
    baseUrl: "http://localhost:3002",
    blobBaseUrl: "https://foodiestrip.blog",
    imageRoot: "https://cdn.foodiestrip.com/",
    cdn: "https://cdn.foodiestrip.com/",
    login: "http://localhost:3000/signin"
  },
  staging: {
    baseUrl: "https://f4t-g5s-a75a-8f-6f-4q.foodiestrip.com",
    blobBaseUrl: "https://foodiestrip.blog",
    imageRoot: "https://cdn.foodiestrip.com/",
    cdn: "https://cdn.foodiestrip.com/",
    login: "https://login.foodiestrip.com/"
  },
  production: {
    baseUrl: "https://support.foodiestrip.com",
    blobBaseUrl: "https://foodiestrip.blog",
    imageRoot: "https://cdn.foodiestrip.com/",
    cdn: "https://cdn.foodiestrip.com/",
    login: "https://login.foodiestrip.com/"
  }
};

export default configuration;
