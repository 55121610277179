import config from "config/config.js";
import getFreshToken from "api/xsrfToken.js";

const resolveAbuse = body => {
  console.log(body);
  // const endpoint = `${config.baseUrl}/api/tb/restaurants/update`;
  // const endpoint = "https://www.foodiestrip.com/api/tb/restaurants/update";
  const xsrfToken = getFreshToken();
  const endpoint = `${config.baseUrl}/api/photo/resolve/abuse`;
  const options = {
    method: "PUT",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "${config.baseUrl}",
      "Access-Control-Allow-Credentials": true,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-XSRF-TOKEN": xsrfToken
    },
    credentials: "include",
    body: JSON.stringify(body)
  };
  return fetch(endpoint, options)
    .then(response => {
      if (response.status !== 200) {
        return null;
      }
      return response.json();
    })
    .then(response => {
      // eslint-disable-next-line no-console
      if (response.error && response.error.status && response.error.status === 401) {
        window.location.href = config.login
      }
      return response;
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.log("errore", e);
    });
};

export default resolveAbuse;
