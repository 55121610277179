import ReviewList from "view2/ReviewList.js";

import connectFunction from "redux/connectFunction";

function mapStateToProps(state) {
  return {
    categories: state.categories,
    aler: state.alert
  };
}

const ReviewListContainer = connectFunction(mapStateToProps)(ReviewList);
export default ReviewListContainer;
