import React, { useState, useMemo, useEffect } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Check from "@material-ui/icons/Check";

// @material-ui/icons
// import Assignment from "@material-ui/icons/Assignment";
import Lock from "@material-ui/icons/Lock";
import LockOpen from "@material-ui/icons/LockOpen";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";

import getSuggestedRestaurant from "api/restaurant/getSuggestedRestaurant.js";
import updateRestaurant from "api/restaurant/updateRestaurant.js";
import deleteRestaurant from "api/restaurant/deleteRestaurant.js";
import approveRestaurant from "api/restaurant/approveRestaurant.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const [restaurant, setRestaurant] = useState([]);
  const rederData = useMemo(() => {
    console.log(restaurant);
    return restaurant.map(prop => {
      console.log(prop, prop.checkIn);
      return {
        _id: prop._id,
        isPending: prop.checkIn.some(c => c.status === "pending"),
        alias: prop.alias,
        nome: prop.name,
        username: prop.createByUser ? prop.createByUser.username : "",
        isLocked: prop.isLocked || false,
        checkIn: prop.checkIn || [],
        checkInNumber: prop.checkIn.length
      };
    });
  }, [restaurant]);
  useEffect(() => {
    getSuggestedRestaurant().then(res => {
      console.log(res);
      setRestaurant(res.response);
      return null;
    });
  }, []);
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              {"Lista Ristoranti Suggeriti"}
            </h4>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={rederData}
              filterable
              columns={[
                {
                  Header: "_id",
                  accessor: "_id"
                },
                {
                  Header: "alias",
                  accessor: "alias",
                  Cell: function alias(row) {
                    return (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.foodiestrip.com/it/ristorante/${row.original.alias}`}
                      >
                        {row.row.alias}
                      </a>
                    );
                  }
                },
                {
                  Header: "nome",
                  accessor: "nome"
                },
                {
                  Header: "username",
                  accessor: "username"
                },
                {
                  Header: "check-in",
                  accessor: "checkInNumber"
                },
                {
                  Header: "funzioni",
                  headerStyle: {
                    textAlign: "left"
                  },
                  accessor: "",
                  sortable: false,
                  Filter: function Fil() {
                    return <div>{"edit/conferma/blocca/elimina"}</div>;
                  },
                  Cell: function Fun(row) {
                    return (
                      <div className="actions-center">
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            window.open(
                              `https://backoffice.foodiestrip.com/dashboard/?${row.original.alias}`
                            );
                          }}
                          color="info"
                          className="remove"
                        >
                          <Edit />
                        </Button>
                        {/* use this button to add a like kind of action */}
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            if (row.original.isPending) {
                              alert("c'è un check-in in pending");
                            } else if (row.original.isLocked) {
                              alert("è stato bloccato da un altro admin");
                            } else {
                              return approveRestaurant({
                                alias: row.original.alias,
                                createByUser:
                                  restaurant[row.index].createByUser._id
                              }).then(() => {
                                const r = [].concat(restaurant);
                                r.splice(row.index, 1);
                                setRestaurant(r);
                              });
                            }
                          }}
                          color="success"
                          className="edit"
                        >
                          <Check />
                        </Button>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            const r = [].concat(restaurant);
                            r[row.index].isLocked = !restaurant[row.index]
                              .isLocked;
                            setRestaurant(r);
                            return updateRestaurant({
                              _id: row.original._id,
                              isLocked: r[row.index].isLocked
                            });
                          }}
                          color={row.original.isLocked ? "danger" : "warning"}
                          className="like"
                        >
                          {row.original.isLocked ? <Lock /> : <LockOpen />}
                        </Button>
                        <Button
                          justIcon
                          round
                          simple
                          onClick={() => {
                            if (row.original.isPending) {
                              alert("c'è un check-in in pending");
                            } else if (row.original.isLocked) {
                              alert("è stato bloccato da un altro admin");
                              return null;
                            } else {
                              return deleteRestaurant(row.original._id).then(
                                () => {
                                  const r = [].concat(restaurant);
                                  r.splice(row.index, 1);
                                  setRestaurant(r);
                                }
                              );
                            }
                          }}
                          color="rose"
                          className="remove"
                        >
                          <Close />
                        </Button>
                      </div>
                    );
                  }
                }
              ]}
              defaultPageSize={25}
              showPaginationTop={false}
              showPaginationBottom
              getTrProps={(state, rowInfo) => {
                if (rowInfo) {
                  if (rowInfo.original.isLocked || rowInfo.original.isPending) {
                    return {
                      style: {
                        background: "yellow"
                        // color: "black"
                      }
                    };
                  }
                }
                return {};
              }}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
