import React, { useState, useMemo } from "react";
import config from "config/config.js";

// react component for creating dynamic tables
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import style from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  ...style,
  right: {
    float: "right"
  },
  left: {
    float: "left"
  },
  center: { margin: " 0 auto" },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};
let updateRedux = null;

const useStyles = makeStyles(styles);
export default function Alert(props) {
  updateRedux = props.updateAlert;
  React.useEffect(() => {
    console.log(props.alert);
  }, [props.alert]);

  const classes = useStyles();
  let confirmBtnCssClass = `${classes.button}  ${classes.success}`;
  if (props.alert && props.alert.showCancel) {
    confirmBtnCssClass += " " + classes.left;
  }
  let confirm = () => {
    props.updateAlert(null);
  };
  if (props.alert && props.alert.confirm) {
    confirm = props.alert.confirm;
  }

  return (
    <>
      {props.alert ? (
        <SweetAlert
          success={props.alert.success}
          danger={props.alert.danger}
          style={{ display: "block", marginTop: "-100px" }}
          title={props.alert.title}
          onConfirm={confirm}
          onCancel={() => {
            props.updateAlert(null);
          }}
          cancelBtnCssClass={
            classes.button + " " + classes.danger + " " + classes.right
          }
          confirmBtnCssClass={confirmBtnCssClass}
          confirmBtnText={props.alert.confirmBtnText}
          cancelBtnText="Chiudi"
          showCancel={props.alert.showCancel}
          // confirmBtnCssClass={classes.button + " " + classes.success}
        >
          {props.alert.text}
        </SweetAlert>
      ) : null}
    </>
  );
}

export { updateRedux };
