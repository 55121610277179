import React, { useState } from 'react';
import loading from "assets/img/loading.gif";

export default function Spinner(props){
  const { show } = props;
  return (
    <>
      {
        show ?
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 99999999999,
            backgroundColor: 'rgba(255,255,255,0.4)'
          }}>
            <img
              src={loading}
            />
          </div>
        : null
      }
    </>
  )
}
