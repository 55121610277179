import React, { useState, useEffect, useMemo } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import HeatmapLayer from "react-leaflet-heatmap-layer";

// import "https://unpkg.com/leaflet@1.1.0/dist/leaflet.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Check from "@material-ui/icons/Check";
import CustomInput from "components/CustomInput/CustomInput.js";

import Close from "@material-ui/icons/Close";

import Button from "components/CustomButtons/Button.js";

import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import getBlobs from "api/blob/getBlobs.js";
import getChange from "api/changePosition/get.js";
import RenderMap from "componentCustom/RenderMap.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
const redIcon = L.icon({
  iconRetinaUrl: require("assets/img/leafletIcon/marker-icon-2x-red.png"),
  iconUrl: require("assets/img/leafletIcon/marker-icon-red.png"),
  shadowUrl: require("assets/img/leafletIcon/marker-shadow.png")
});
const blueIcon = L.icon({
  iconRetinaUrl: require("assets/img/leafletIcon/marker-icon-2x-blue.png"),
  iconUrl: require("assets/img/leafletIcon/marker-icon-blue.png"),
  shadowUrl: require("assets/img/leafletIcon/marker-shadow.png")
});
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions(blueIcon);
//   {
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png")
// });
Object.assign(styles, {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
});

const useStyles = makeStyles(styles);

export default function Maps() {
  const classes = useStyles();
  // const markerPlain = L.icon({
  //   iconUrl: `https://cdn.foodiestrip.com/pins/categories/category10-70.png`,
  //   iconSize: [35, 35],
  //   iconAnchor: [15, 34]
  // });
  const [data, setData] = useState([]);

  useEffect(() => {
    getChange().then(a => {
      console.log(a);
      setData(a);
    });
  }, []);
  // const
  // this.props.suggestion.restaurantCheckIn.forEach(ci => {
  //   ci.userLocations.forEach(location => {
  //     const position = [location[1], location[0]];
  //     positions.push(position);
  //   });
  // });

  return (
    <GridContainer>
      <GridItem xs={12}>
        {data.map((el, i) => {
          return (
            <RenderMap
              key={el._id}
              dataIndex={i}
              restaurant={el}
              handleChange={(dataIndex, proposedIndex) => {
                const d = JSON.parse(JSON.stringify(data));
                console.log(proposedIndex);
                d[dataIndex].proposed.splice(proposedIndex, 1);
                console.log(d);
                if (d[dataIndex].proposed.length === 0) {
                  d.splice(dataIndex, 1);
                }
                setData(d);
              }}
            />
          );
        })}
      </GridItem>
    </GridContainer>
  );
}
