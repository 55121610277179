import React, { useState, useMemo } from "react";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import CardFooter from "components/Card/CardFooter.js";
import Add from "@material-ui/icons/Add";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import getByAlias from "api/restaurant/getByAlias.js";
import mergeByAlias from "api/restaurant/mergeByAlias.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  restaurantName: {
    textAlign: "center"
  },
  score: {
    borderRadius: "50%",
    margin: "0 auto",
    border: "1px solid",
    width: "40px",
    height: "40px",
    lineHeight: "30px",
    padding: "5px"
  }
};

const useStyles = makeStyles(styles);

export function RenderInput(prop) {
  const [value, setValue] = useState("");
  return (
    <>
      <GridItem xs={10}>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          inputProps={{
            value: value,
            onChange: e => {
              setValue(e.target.value);
            }
          }}
        />
      </GridItem>
      <GridItem xs={2}>
        <Button
          justIcon
          round
          simple
          color={"info"}
          style={{ marginTop: "15px" }}
          onClick={() => {
            prop
              .onClick(value)
              .then(() => {
                setValue("");
              })
              .catch(value => {
                alert(value);
              });
          }}
        >
          {/* aggiungi */}
          <Add />
        </Button>
      </GridItem>
    </>
  );
}
export default function MergeAlias() {
  const [aliases, setAliases] = useState([]);
  console.log(aliases);
  const classes = useStyles();
  return (
    <GridContainer>
      {/* {aliases.map((el, i) => (
        <> */}
      <RenderInput
        onClick={value => {
          if (!aliases.some(el => el.alias === value)) {
            return getByAlias(value.trim()).then(data => {
              if (data && data.response) {
                console.log(data);
                const alias = [...aliases];
                alias.push(data.response);
                setAliases(alias);
              } else {
                return Promise.reject("non trovato");
              }
            });
          } else {
            return Promise.reject("sei un birbone alias già inserito");
          }
        }}
      />

      {aliases.map((el, i) => {
        return (
          <GridItem key={el._id} xs={12}>
            <Card>
              <CardHeader>
                {el.owner && el.owner.username ? (
                  <h4>
                    <b>{"Proprietario: "}</b>
                    {el.owner.username}
                  </h4>
                ) : (
                  ""
                )}
                {el.owner && el.owner.username ? (
                  <CardAvatar
                    profile
                    className={classes.cardAvatar}
                    style={{
                      margin: 0,
                      maxWidth: "50px",
                      maxHeight: "50px",

                      position: "absolute",
                      left: "-25px",
                      top: "-30px"
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={el.owner.profileImageUrl}
                    />
                  </CardAvatar>
                ) : // el.owner.username
                null}
                <Button
                  justIcon
                  round
                  simple
                  style={{ position: "absolute", right: 0, top: 0 }}
                  size={"lg"}
                  color="rose"
                  onClick={() => {
                    const alias = [...aliases];
                    alias.splice(i, 1);
                    setAliases(alias);
                  }}
                  className="remove"
                >
                  <Close />
                </Button>
              </CardHeader>
              <CardBody>
                <h4 style={{ width: "100%" }}>{el.name}</h4>
                {el.address && el.address.formattedAddress
                  ? el.address.formattedAddress
                  : ""}
                <GridItem xs={12} container>
                  {el.scoreOverall ? (
                    <GridItem xs={3}>
                      <div className={classes.score}>
                        {el.scoreOverall.toFixed(2)}
                      </div>
                      <div className={classes.restaurantName}>{"Totale"}</div>
                    </GridItem>
                  ) : null}
                  {el.scoreService ? (
                    <GridItem xs={3}>
                      <div className={classes.score}>
                        {el.scoreService.toFixed(2)}
                      </div>
                      <div className={classes.restaurantName}>{"Servizio"}</div>
                    </GridItem>
                  ) : null}
                  {el.scorePlace ? (
                    <GridItem xs={3}>
                      <div className={classes.score}>
                        {el.scorePlace.toFixed(2)}
                      </div>
                      <div className={classes.restaurantName}>{"Location"}</div>
                    </GridItem>
                  ) : null}
                  {el.scoreFood ? (
                    <GridItem xs={3}>
                      <div className={classes.score}>
                        {el.scoreFood.toFixed(2)}
                      </div>
                      <div className={classes.restaurantName}>{"Menu"}</div>
                    </GridItem>
                  ) : null}
                </GridItem>
              </CardBody>
              <CardFooter>
                {el.certificationRequest &&
                el.certificationRequest.status &&
                el.certificationRequest.status === "fulfilled" ? (
                  <>
                    <div></div>
                    <div style={{ float: "right" }}>
                      {"certiticato "} <Check />
                    </div>
                  </>
                ) : null}
              </CardFooter>
            </Card>
          </GridItem>
        );
      })}
      {aliases.length > 1 ? (
        <Button
          style={{ position: "fixed", bottom: 50, right: 50 }}
          round
          color="success"
          onClick={() => {
            const ids = aliases.map(el => el._id);
            const alias = aliases.map((el, i) => {
              if (i > 0) {
                return el.alias;
              }
              return null;
            });

            return mergeByAlias({ ids, aliases: alias.filter(Boolean) }).then(
              response => {
                console.log(response);
                if (response && response.message) {
                  setAliases([]);
                }
              }
            );
          }}
        >
          Merge
        </Button>
      ) : null}
    </GridContainer>
  );
}
