export const initialState = {
  categories: [],
  alert: null
};

export const reducer = (action, state) => {
  switch (action.type) {
    case "UPDATE_CATEGORIES":
      return { ...state, categories: action.payload };
    case "UPDATE_ALERT":
      return { ...state, alert: action.payload };
    default:
      return state;
  }
};

export default function mapDispatchToProps(dispatch) {
  return {
    updateCategories: payload =>
      dispatch({ type: "UPDATE_CATEGORIES", payload }),
    updateAlert: payload => dispatch({ type: "UPDATE_ALERT", payload })
  };
}
