import React, { useContext } from "react";
import mapDispatchToProps from "redux/reducer.js";
import { MyContext } from "redux/Context.js";

function connect(mapStateToProps) {
  return function(Component) {
    return function() {
      const { state, dispatch } = useContext(MyContext);
      const stateToProps = mapStateToProps(state);
      const dispatchToProps = mapDispatchToProps(dispatch);
      const props = { ...props, ...stateToProps, ...dispatchToProps };
      return <Component {...props} />;
    };
  };
}
export default connect;
