import React, { useState, useEffect } from "react";
import L from "leaflet";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";

import Search from "@material-ui/icons/Search";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Map, Marker, TileLayer } from "react-leaflet";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";

import searchFromGoogle from "api/addRestaurant/searchFromGoogle.js";
import addRestaurant from "api/addRestaurant/addRestaurant.js";
import getCategories from "api/getCategories.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function AddRestaurant(props) {
  const classes = useStyles();
  const blueIcon = L.icon({
    iconRetinaUrl: require("assets/img/leafletIcon/marker-icon-2x-blue.png"),
    iconUrl: require("assets/img/leafletIcon/marker-icon-blue.png"),
    shadowUrl: require("assets/img/leafletIcon/marker-shadow.png"),
    iconSize: [22, 36],
    iconAnchor: [11, 36]
  });
  const [position, setPosition] = useState({ lng: 12, lat: 42 });
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [alias, setAlias] = useState("");
  const [hotel, setHotel] = useState(false);
  const [category, setCategory] = useState(10);
  const [reset, setReset] = useState(false);

  const key = "AIzaSyC0cC0Nzc3MRF5M98TwpfHEvmfnXJ9XaxU";
  // const [position, setPosition] = useState({ lng: 12, lat: 42 });
  useEffect(() => {
    if (props.categories && props.categories.length === 0) {
      getCategories().then(categories => {
        props.updateCategories(categories);
      });
    }
  }, []);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          labelText={"Nome ristorante"}
          inputProps={{
            value: name,
            onChange: e => {
              setName(e.target.value);
            }
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          labelText={"Alias"}
          inputProps={{
            value: alias,
            onChange: e => {
              setAlias(e.target.value);
            }
          }}
        />
      </GridItem>
      <GridItem xs={11}>
        <CustomInput
          formControlProps={{ fullWidth: true }}
          labelText={"Indirizzo"}
          inputProps={{
            value: address,
            onChange: e => {
              setAddress(e.target.value);
            }
          }}
        />
      </GridItem>
      <GridItem xs={1}>
        <Button
          justIcon
          round
          simple
          color={"info"}
          size="lg"
          onClick={() => {
            searchFromGoogle(address, key).then(resp => {
              if (resp) {
                setPosition(resp);
              }
            });
          }}
        >
          <Search />
        </Button>
      </GridItem>
      <GridItem xs={4}>
        <Select
          value={category}
          onChange={e => {
            setCategory(e.target.value);
          }}
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          style={{ width: "100%" }}
        >
          {props.categories.map(el => (
            <MenuItem
              key={el._id}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
              }}
              value={el._id}
            >
              {el.label_it}
            </MenuItem>
          ))}
        </Select>
      </GridItem>

      <GridItem xs={12}>
        {"é un hotel?"}
        <Checkbox
          checked={hotel}
          onClick={() => {
            setHotel(!hotel);
          }}
          checkedIcon={<Check className={classes.checkedIcon} />}
          // icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
        />
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardHeader></CardHeader>
          <CardBody>
            <Map
              id={"cdc"}
              center={[position.lat, position.lng]}
              maxZoom={18}
              scrollWheelZoom={false}
              zoom={16}
              length={4}
              style={{ width: "100%", height: "500px", overflow: "hidden" }}
            >
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                icon={blueIcon}
                anchor={0.5}
                draggable
                onDragEnd={e => {
                  const marker = e.target;
                  const newPosition = marker.getLatLng();
                  setPosition({ lat: newPosition.lat, lng: newPosition.lng });
                }}
                position={position}
              />
            </Map>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12}>
        <Button
          onClick={() => {
            return addRestaurant({
              coordinates: position,
              name: name,
              alias: alias,
              category: category,
              language: "it",
              type: hotel ? "hotel" : "restaurant"
            }).then(res => {
              if (res && res.message) {
                setPosition({ lng: 12, lat: 42 });
                setAddress("");
                setName("");
                setAlias("");
                setHotel(false);
                setCategory(10);
                setReset(true);
                console.log(position, address, name, category, hotel, alias);
                props.updateAlert({
                  title: "Successo",
                  success: true
                });
              }
              return null;
            });
          }}
        >
          Aggiungi
        </Button>
      </GridItem>
    </GridContainer>
  );
}
