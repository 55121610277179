import CheckInList from "view2/CheckInList.js";

import connectFunction from "redux/connectFunction";

function mapStateToProps(state) {
  return {
    categories: state.categories,
    aler: state.alert
  };
}

const CheckInListContainer = connectFunction(mapStateToProps)(CheckInList);
export default CheckInListContainer;
