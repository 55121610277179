import Alert from "componentCustom/Alert.js";

import connectFunction from "redux/connectFunction";

function mapStateToProps(state) {
  return {
    alert: state.alert
  };
}

const AlertContainer = connectFunction(mapStateToProps)(Alert);
export default AlertContainer;
