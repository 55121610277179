import UserList from "view2/UserList.js";

import connectFunction from "redux/connectFunction";

function mapStateToProps(state) {
  return {
    alert: state.alert
  };
}

const UserListContainer = connectFunction(mapStateToProps)(UserList);
export default UserListContainer;
