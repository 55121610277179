import React, { useState, useEffect } from "react";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";

import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

import getAlias from "api/aliasChange/getAlias.js";
import approveAlias from "api/aliasChange/approveAlias.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  restaurantName: {
    textAlign: "center"
  },
  score: {
    borderRadius: "50%",
    margin: "0 auto",
    border: "1px solid",
    width: "40px",
    height: "40px",
    lineHeight: "30px",
    padding: "5px"
  }
};

const useStyles = makeStyles(styles);

export default function MergeAlias() {
  const [aliases, setAliases] = useState([]);
  useEffect(() => {
    getAlias().then(data => {
      setAliases(data.response);
    });
  }, []);
  console.log(aliases);
  const classes = useStyles();
  return (
    <GridContainer>
      {aliases.map((el, i) => {
        return (
          <GridItem key={el._id} xs={12}>
            <Card>
              <CardHeader>
                <h4>
                  <b>{el.name}</b>
                </h4>
                <h5>{el.address && el.address.formattedAddress ? el.address.formattedAddress : "Indirizzo non disponibile"}</h5>
              </CardHeader>
              <CardBody>
                <h4>
                  {"Vecchio alias: "} <b>{el.alias}</b>
                </h4>
                <h4>
                  {"Nuovo alias: "}
                  <b>{el.aliasRequest}</b>
                </h4>
              </CardBody>
              <CardFooter>
                <>
                  <Button
                    color="success"
                    size="lg"
                    round
                    simple
                    justIcon
                    onClick={() => {
                      approveAlias({
                        id: el._id,
                        type: "accept",
                        alias: el.aliasRequest,
                        oldAlias: el.alias
                      }).then(response => {
                        if (response && response.message) {
                          const alias = [...aliases];
                          alias.splice(i, 1);
                          setAliases(alias);
                        }
                      });
                    }}
                  >
                    <Check />
                  </Button>
                  <Button
                    size="lg"
                    round
                    simple
                    justIcon
                    style={{ float: "right" }}
                    color="danger"
                    onClick={() => {
                      approveAlias({
                        id: el._id,
                        type: "delete",
                        alias: el.aliasRequest,
                        oldAlias: el.alias
                      }).then(response => {
                        if (response && response.message) {
                          const alias = [...aliases];
                          alias.splice(i, 1);
                          setAliases(alias);
                        }
                      });
                    }}
                  >
                    <Close />
                  </Button>
                </>
              </CardFooter>
            </Card>
          </GridItem>
        );
      })}
    </GridContainer>
  );
}
